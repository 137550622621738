<nb-card>
  <nb-card-header status="success">
    <label style="font-size: 140%;">Emailed Applications</label>
  </nb-card-header>

  <nb-card-body>
    <form [formGroup]="searchForm" class="row">
      <div class="col-xl-4 form-group">
        <label>Application Id</label><br>
        <input nbInput formControlName="idCtrl" placeholder="Application Id"
          [ngClass]="{'form-control-danger': searchForm.controls.idCtrl.invalid && (searchForm.controls.idCtrl.dirty || searchForm.controls.idCtrl.touched)}">
      </div>
      <div class="col-xl-4 form-group">
        <label>Name</label><br>
        <input nbInput formControlName="nameCtrl" [(ngModel)]="name" placeholder="name"
          [ngClass]="{'form-control-danger': searchForm.controls.nameCtrl.invalid && (searchForm.controls.nameCtrl.dirty || searchForm.controls.nameCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <label>Email ID</label><br>
        <input nbInput formControlName="emailCtrl" [(ngModel)]="email" placeholder="email"
          [ngClass]="{'form-control-danger': searchForm.controls.emailCtrl.invalid && (searchForm.controls.emailCtrl.dirty || searchForm.controls.emailCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <button nbButton (click)="search()">Search</button>
      </div>
    </form>


    <br>
    <div class="row">
      <div class="col-xl-4 ">
        <input matInput type="text" [value]="filterText" [placeholder]="filterPlaceholder"
          [formControl]="filterInput" />
        <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText=''">
          <mat-icon>close</mat-icon>
        </button>
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>
    </div>

    <div class="wrapper">
      <h5><b>Total student count: {{studentLength?.totalLength}} </b></h5><br /><br />

        <table id="selectedColumn">
          <thead>
            <th>App_Id</th>
            <th>Name As Per Marksheet</th>
            <th>Email ID</th>
            <th>Application Tracker</th>
            <th>Tracking Details</th>
            <th>Print Certificate</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr
              *ngFor="let student of studentdata | filter:{name:filterText, email:filterText, id:filterText} | paginate: { itemsPerPage: 10, totalItems: totalLength,currentPage: p }">
                <td>{{student?.id}}</td>
                
              
                <td>{{student?.fullname}}</td>
              
             
                <td>{{student?.email}}</td>
              <td> Done by:{{student?.print_by}}<br>
                   courier_date:{{student?.courier_date}}</td>
               
              
  <td><div class="Rtable-cell--content date-content">{{student?.tracking_id}} {{student?.courier_name}}</div>
  </td>
                <td>
                  <i class="fa fa-file-pdf-o fa-5x" aria-hidden="true" status="info" nbPopover="View More"
                    nbPopoverMode="hover"
                    [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"
                    (click)="printJS(student.user_id,student.id,student.filepath)"></i><br>
                
                </td>
  
                <td>
                  <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                    (click)="handleClick(student.user_id,student.id)"></i><br>
                  <button nbButton outline size="small"
                    *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                    (click)="verifyOtp('0', '0', student.id);">Verify</button>
                  <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                    (click)="status('inactive',student.id);">inactive</button>
                  <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                    (click)="status('active',student.id);">active</button>
                <i class="fa ion-refresh" status="info" nbPopover="View More" nbPopoverMode="hover" (click)="sendToPrintByEdulab(student.id)"></i>
                </td>
  
            </tr>
          </tbody>
        </table>

      <div *ngIf="studentLength?.totalLength == 0">
        <span style="color : red; font-size: 18px;">No Data Available</span>
      </div>
    </div>
    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
  </nb-card-body>
</nb-card>