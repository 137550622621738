import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from './http.service';

@Injectable()
export class ThirdPartyApplicationsApi {
  private readonly apiController: string = 'thirdPartyApplications';

  constructor(private api: HttpService,
    private http:HttpClient) { }

    addThirdPartyApplications(item){
        return this.http.post(`${environment.apiUrl}/addThirdPartyApplications`, item)
    } 
    
    
    getThirdPartyApplications(id){
      return this.http.get(`${environment.apiUrl}/getThirdPartyApplications?id=`+id)
    }

   

   

}