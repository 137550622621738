import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var machineID = localStorage.getItem('MachineId');
        const modifiedRequest = request.clone({
            setHeaders: {
                'Machinid': machineID ? machineID : 'ABC'
            }
        });
        return next.handle(modifiedRequest);
    }
}