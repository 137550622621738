<div class="row mcr">
  <div class="col-md-6 form">

    <div class="logo1">
      <img src=".\assets\images\sulogo.png">
    </div>

    <h1 style="text-align: center; color: DodgerBlue;">Register</h1>

    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
      <p class="alert-title"><b>Oh snap!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
      </ul>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
      <p class="alert-title"><b>Hooray!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
      </ul>
    </nb-alert>

    <div [formGroup]="registerForm" aria-labelledby="title">

      <div class="form-control-group">
        <label for="input-name">Applicant First Name:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-name" pattern="[a-zA-Z ]*"
          (keyup)="OnlyCharAllowed($event)" style="text-transform: capitalize;" autocomplete="given-name" required
          formControlName="firstName" [status]="firstName.dirty ? (firstName.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="firstName.invalid && firstName.touched ? true : null" placeholder="First Name">
        <span
          *ngIf="registerForm.controls.firstName.value?.length > 1 && registerForm.controls.firstName.invalid && (registerForm.controls.firstName.dirty || registerForm.controls.firstName.touched)"
          style="color: red;">Special characters or numbers are not allowed.</span>

        <span
          *ngIf="registerForm.controls.firstName && (registerForm.controls.firstName.dirty && registerForm.controls.firstName.touched && registerForm.controls.firstName.value.length == '')"><span
            style="color:red;">First name is required</span></span>
      </div>

      <div class="form-control-group">
        <label for="input-name">Applicant Last Name:</label>
        <input nbInput autofocus fullWidth fieldSize="large" (keyup)="OnlyCharAllowed($event)"
          style="text-transform: capitalize;" id="input-name" formControlName="lastName"
          [status]="lastName.dirty ? (lastName.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="lastName.invalid && lastName.touched ? true : null" placeholder="Last Name">
        <span
          *ngIf="registerForm.controls.lastName.value?.length > 1 && registerForm.controls.lastName.invalid && (registerForm.controls.lastName.dirty || registerForm.controls.lastName.touched)"
          style="color: red;">Special characters or numbers are not allowed.</span>

        <span
          *ngIf="registerForm.controls.lastName.invalid && (registerForm.controls.lastName.dirty || registerForm.controls.lastName.touched )"><span
            style="color:red;">Last name is required</span></span>
      </div>
      <div class="form-control-group">
        <label for="input-name">Applicant Full Name :<br>(As on marksheet)</label>
        <input nbInput autofocus fullWidth fieldSize="large" (keyup)="OnlyCharAllowed($event)"
          style="text-transform: capitalize;" id="input-name" formControlName="fullname"
          [status]="fullname.dirty ? (fullname.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="fullname.invalid && fullname.touched ? true : null" placeholder="Full Name">
        <span
          *ngIf="registerForm.controls.fullname.value?.length > 1 && registerForm.controls.fullname.invalid && (registerForm.controls.fullname.dirty || registerForm.controls.fullname.touched)"
          style="color: red;">Special characters or numbers are not allowed.</span>

        <span
          *ngIf="registerForm.controls.fullname.invalid && (registerForm.controls.fullname.dirty || registerForm.controls.fullname.touched )"><span
            style="color:red;">Full name is required</span></span>

      </div>
      <div class="form-control-group">
        <label for="input-aadhar">Aadhar/Passport Number : </label>
        <input nbInput fullWidth fieldSize="large" id="input-aadhar" pattern="[0-9]{12,12}"
          (keypress)="numbersonly($event)" formControlName="aadhar" placeholder="Aadhar/Passport Number">
          <span *ngIf="registerForm.controls.aadhar.invalid && (registerForm.controls.aadhar.dirty || registerForm.controls.aadhar.touched)">
            <span style="color:red;">Please enter valid Adhar/Passport Number</span></span><br>

        <span
          *ngIf="registerForm.controls.aadhar.invalid && (registerForm.controls.aadhar.dirty || registerForm.controls.aadhar.touched)"><span
            style="color:red;">Aadhar/Passport Number is required</span></span>
      </div>
      <!-- <div class="form-control-group">
        <nb-form-field class="example-full-width">
          <label for="">Select Course</label>
          <input type="text" placeholder="Select Course" aria-label="Select Course" nbInput [nbAutocomplete]="auto"
            [formControl]="courseCntrl">
          <nb-autocomplete #auto>
            <nb-option *ngFor="let course of filteredCourses | async">
              <div (click)="optionClicked($event, course)">
                {{ course.name }}
             
              </div>
            </nb-option>
          </nb-autocomplete>
        </nb-form-field>
        <span
          *ngIf="registerForm.controls.degreeCtrl.invalid && (registerForm.controls.degreeCtrl.dirty || registerForm.controls.degreeCtrl.touched)"><span
            style="color:red;">Course Name is required</span></span>
      </div>

      <div class="form-control-group">
        <label for="input-course">PRN Number for Selected Courses :</label>
        <nb-list dense>
          <nb-list-item *ngIf="selectedCourses?.length === 0" style="width: 100%;">(None)</nb-list-item>
          <nb-list-item *ngFor="let course of selectedCourses"
            style="display: flex; flex-direction: column; height: auto; justify-content: left;">
            <div>{{ course.course}}</div>
            <div>
              <input nbInput (blur)="setEnrollmentNo(course.course,course.degree_type,$event)" pattern="[0-9]{12,16}" formControlName="enrollment"
                 (keypress)="numbersonly($event)">
                <br>
                <span *ngIf="registerForm.controls.enrollment.invalid && (registerForm.controls.enrollment.dirty || registerForm.controls.enrollment.touched )">
                  <span   style="color:red;">Please enter valid PRN Number</span></span>
            </div>


          </nb-list-item>
        </nb-list>

      </div> -->
      <div class="form-control-group">
        <label for="input-name">Gender:</label><br>
        <select formControlName="gender" class="gender" style="width: 100%;">
          <option disabled>Select Gender</option>
          <option>Male</option>
          <option>Female</option>
          <option>Other</option>

        </select>

        <span
          *ngIf="registerForm.controls.gender.invalid && (registerForm.controls.gender.dirty || registerForm.controls.gender.touched)"><span
            style="color:red;">Gender is required</span></span>

      </div><br>

      <div class="row">

        <div>
          <label for="inputMobile"> Country Code and Mobile number <span style="color:red;">* </span></label>
        </div>
      </div>
      <div class="row">
        <div>

        </div>

        <div>
          <div class="form-group">
            <input nbInput type="text" pattern="[0-9]{10,15}" class="form-control" placeholder="Mobile No" fullWidth
              fieldSize="large" formControlName="mobile" class="countrycode" inputmode="numeric" digitOnly ng2TelInput
              [ng2TelInputOptions]="{separateDialCode:true}" (countryChange)="onCountryChange($event)"
              (keypress)="OnlyNoAllowed($event)"  required>
            <span
              *ngIf="registerForm.controls.mobile.invalid && (registerForm.controls.mobile.dirty || registerForm.controls.mobile.touched)"><span
                style="color:red;">Mobile number is required</span></span>
                <br>
                <span style="color :red" *ngIf="registerForm.controls.mobile.invalid && (registerForm.controls.mobile.dirty || registerForm.controls.mobile.touched)">Please enter valid mobile
                  number</span>
          </div>
        </div>
      </div>
      <br>

      <div class="form-control-group">
        <label for="input-email">Email Address:</label>
        <div>

        </div>
        <input (keyup)="onKeyPressNew($event)" nbInput fullWidth fieldSize="large" id="input-email"
          formControlName="email" [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="email.invalid && email.touched ? true : null" placeholder="Email address">
        <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
          [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
      </div>

      <div class="form-control-group">
        <label for="input-password">Password:</label>
        <div style="position: relative; height: 48px;">

          <input nbInput fullWidth fieldSize="large" id="input-password" formControlName="password"
            [type]="changetype? 'password' : 'text' "
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" placeholder="Password">
          <span class="eyeicon" (click)="viewpass()"><i [ngClass]="viewpass? 'fa fa-eye':'fa fa-eye-slash'"></i></span>
        </div>

        <span
          *ngIf="registerForm.controls.password.value?.length < 6 && registerForm.controls.password.invalid && (registerForm.controls.password.dirty)"
          style="color: red;">Password should be 6 and more characters</span>
        <span
          *ngIf="registerForm.controls.password.value?.length < 0 && (registerForm.controls.password.dirty || registerForm.controls.password.touched)"><span
            style="color:red;">ConfirmPassword is required</span></span>

      </div>

      <div class="form-control-group">
        <label for="input-re-password">Confirm Password:</label>
        <div style="position: relative; height: 48px;">

          <input nbInput fullWidth fieldSize="large" id="input-re-password" formControlName="confirmPassword"
            [type]="changetype? 'password' : 'text' "
            [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
            placeholder="Password">
          <span class="eyeicon" (click)="viewpass()"><i [ngClass]="viewpass? 'fa fa-eye':'fa fa-eye-slash'"></i></span>
        </div>
        <span
          *ngIf="registerForm.controls.confirmPassword.value?.length < 6 && registerForm.controls.confirmPassword.invalid && (registerForm.controls.confirmPassword.dirty)"
          style="color: red;">Password should be 6 and more characters</span>
        <span
          *ngIf="registerForm.controls.confirmPassword.value?.length < 0 && (registerForm.controls.confirmPassword.dirty || registerForm.controls.confirmPassword.touched)"><span
            style="color:red;">ConfirmPassword is required</span></span>

        <p class="caption status-danger" *ngIf="password.value != confirmPassword.value">
          Password and confirm password does not match!
        </p>
      </div>

            <button nbButton fullWidth status="primary" size="large" formControlName="terms" [class.btn-pulse]="submitted"  
        *ngIf="(firstName.value !=''&& lastName.value != '' && fullname.value != '' && (registerForm.controls.aadhar.value.length == 12 || registerForm.controls.aadhar.value.length == 12)  &&
        gender.value != ''  && mobile.value.length == 10 && email.value != ''  &&  password.value != '' && confirmPassword.value ==  password.value )"
        (click)="toggle('danger')">
        Proceed
      </button>

      <h6 class="login1" aria-label="Sign in">
        Already have an account? <a class="text-link" style="color:DodgerBlue;" routerLink="../login">Log in</a>
      </h6>
    </div>
  </div>
  <div class="col-md-6 about">
    <div class="elip"></div>
    <div class="logo">
      <img src=".\assets\images\sulogo.png" style="width: 190px; margin: 0 auto;" alt="logo">
    </div>

    <div style="position: relative; ">
      <div style="position: absolute; z-index: 5; width: 100%;">
        <h4> Already have an account? <a style="color:DodgerBlue;" class="text-link" routerLink="../login">Login</a>
        </h4>
      </div>
    </div>

    <div class="imge">
      <img src="./../../../../assets/images/Verified-rafiki.svg">
    </div>

  </div>
</div>

<p-dialog [(visible)]="displayNo" [modal]="true" [responsive]="true" [style]="{width: '90vw', minWidth: '70vw'}"
  [minY]="70" [baseZIndex]="1">
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-11">
      <nb-checkbox [checked]="checked1" (checkedChange)="toggle1('1',$event)"> I am aware that Solapur University may
        communicate with me by mail, email, phone or text message/Whatsapp for my application.</nb-checkbox>



    </div>
  </div><br>
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-11">
      <nb-checkbox [checked]="checked2" (checkedChange)="toggle1('2',$event)">
        By checking this box, I represent that (i) I am age 13 or older and (ii) I have read, understood and agreed to
        the terms and conditions of the Terms of Use and Privacy Policy (unless I am over the age of 13 but under the
        age of 18, in which case, my parent or legal guardian has also read, understood and agreed to the terms and
        conditions of the Privacy Policy and Terms of Use sections).

      </nb-checkbox>
    </div>
  </div><br>
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-11">
      <nb-checkbox [checked]="checked3" (checkedChange)="toggle1('3',$event)">
        I accept that (I) Fees paid is not refundable And (II) All the information filled by me is true.

      </nb-checkbox>
    </div>
  </div><br>
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-11">
      <nb-checkbox [checked]="checked4" (checkedChange)="toggle1('4',$event)">
        Solapur University website uses small files stored on your computer, known as cookies, that help us remember
        your settings and ensure the website works properly. By continuing, I am agreeing to The Solapur University use
        of cookies.
      </nb-checkbox>
    </div>
  </div><br>

  <button nbButton fullWidth status="primary" size="large" [disabled]="submitted  || !checked1 ||!checked2||!checked3||!checked4
|| password.value != confirmPassword.value" [class.btn-pulse]="submitted" (click)="register()">
    Register
  </button>
</p-dialog>

<p-dialog [(visible)]="emailverify" [modal]="true" [responsive]="true" [style]="{width: '70vw', minWidth: '90vw'}">
  <h3>Kindly Change Your Email Address</h3>

</p-dialog>
<p-dialog [(visible)]="displayNo1" [modal]="true" [responsive]="true" [style]="{width: '80%'}">
  <div style="text-align:center">
    <embed src="{{imageUrl}}/api/sample/pdcgeneral.pdf" width="800" height="500">
  </div>

</p-dialog>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>