import { Component, Input} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ActivatedRoute, Router} from '@angular/router';
import { ApplicationApi } from '../../@core/backend/common/api/application.api';
import {  NbToastrService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'nb-dialog',
    template: `
    <nb-card [style.width.px]="700" [style.height.px]="600" status="success" accent="success" size="xsmall" nbSpinnerStatus="success" nbSpinnerSize="xlarge">
      <nb-card-header>
        <div class="row">
          <div class="col-md-3">
          </div>
          <div class="col-md-6">
            <h3 style="color:#ffffff"> PAYMENT</h3>
          </div>
          <div class="col-md-3">
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">Name</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="Name"  ngModel="{{userdata[0].name}}"  [readonly]="true">
          </div>
        </div>
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">Address</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="Address" ngModel="{{userdata[0].address}}"   [readonly]="true">
          </div>
        </div>
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">City</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth  placeholder="City"  ngModel="{{userdata[0].city}}"  [readonly]="true">
          </div>
        </div>
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">State</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="State" ngModel="{{userdata[0].state}}"   [readonly]="true">
          </div>
        </div>
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">Zipcode</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="Zipcode" ngModel="{{userdata[0].postal_code}}"  [readonly]="true">
          </div>
        </div>
       <!-- <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">Country</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="Country"  [readonly]="true">
          </div>
        </div> -->
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">Telephone</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="Telephone" ngModel="{{userdata[0].mobile}}"   [readonly]="true">
          </div>
        </div>
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">Email</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="Email"  ngModel="{{userdata[0].email}}"  [readonly]="true">
          </div>
        </div>
        <div class="row" style="margin-bottom:7px">
        <div class="col-md-3">Choose Delivery Type:</div>
        <div class="col-md-9">

        <select name="passMonthCtrl" id="passMonthCtrl" class="form-control"  placeholder="result/class" disabled="disabled" ngModel="Normal">
            <option selected="true"  value="Normal">Normal</option>
        </select>
        </div>
      </div>
      <div *ngIf="other_add!=''">
      <label id="example-radio-group-label"><b>Select address where you want to courier your certificate.</b></label><br>
        <!-- <mat-radio-group
          aria-labelledby="example-radio-group-label" 
          class="example-radio-group" style="display: flex; flex-direction: column;">
            <mat-radio-button class="example-radio-button" *ngFor="let add of other_add" [value]="add.address" (change)="onSelectaddress(add.id,add.address)">
            {{add.address}}
            </mat-radio-button><br>
        </mat-radio-group>-->
        <nb-radio-group >
        <nb-radio   class='radio-button'  *ngFor="let add of other_add" [value]="add" (valueChange)="onSelectaddress(add.id,add.address)" >
        {{add.address}}
        </nb-radio>
        </nb-radio-group>
      <div>
      <div class="row">
      <div *ngIf="other==true">
        <input type="checkbox" (change)='altaddCheck($event)' id="new_addressCtrl"><label>Want to add different address for Courier</label>
      </div>
    </div>
    </div>
    </div>
      <!-- Alternate address for courired-->
      <form class="form" role="form" [formGroup]="secondForm" class="step-container">
          <div class="row"  *ngIf="addCheck == true">
            <div class="col-xl-6" style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
              Address: <span style="color:red;">*</span>
            </div>
            <div class="col-xl-6">
              <mat-form-field style="width:220px !important">
                <input   matInput formControlName="new_addressCtrl" [ngClass]="{'form-control-danger': secondForm.controls.new_addressCtrl.invalid && (secondForm.controls.new_addressCtrl.dirty || secondForm.controls.new_addressCtrl.touched)}">
              </mat-form-field>
              <br/><p style="color:red;width:inherit;text-align: start;" *ngIf="secondForm.controls['new_addressCtrl'].invalid && (secondForm.controls['new_addressCtrl'].dirty || secondForm.controls['new_addressCtrl'].touched)">Please Enter Address!</p>
            </div>
          </div>
          <div class="row" *ngIf="addCheck == true">
            <div class="col-xl-6" style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
              Pincode: <span style="color:red;">*</span>
            </div>
            <div class="col-xl-6">
              <mat-form-field style="width:220px !important">
                <input    pattern="[0-9]+" matInput (keyup)="pincodeChanges($event,'new')" formControlName="new_pincodeCtrl" [ngClass]="{'form-control-danger': secondForm.controls.new_pincodeCtrl.invalid && (secondForm.controls.new_pincodeCtrl.dirty || secondForm.controls.new_pincodeCtrl.touched)}">
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="addCheck == true">
          <div class="col-xl-3">
            <label for="inputarea">Area - Location * </label>
          </div>
          <input *ngIf="editAreaLocation == true"  type="text" style="font-size: 20px; border-bottom-width: thick;"   class="form-control" id="inputarea"  placeholder="Area - Location" formControlName="new_cityEditCtrl"   >
          <nb-select  *ngIf="editAreaLocation == false"  placeholder="Please select a location" class="uk-width-1-1" [ngModel]="city" formControlName="new_cityCtrl" (selectedChange)="onChangeAreaLocation($event)">
            <nb-option *ngFor="let add of addresses" [value]="add" [(selected)]="area">
              {{add.office_name}}
            </nb-option>             
          </nb-select>
        </div>

          <div class="row" *ngIf="addCheck == true">
            <div class="col-xl-6" style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
              District: <span style="color:red;">*</span>
            </div>
            <div class="col-xl-6">
              <mat-form-field style="width:220px !important">
                <input   matInput formControlName="new_districtCtrl" [ngClass]="{'form-control-danger': secondForm.controls.new_districtCtrl.invalid && (secondForm.controls.new_districtCtrl.dirty || secondForm.controls.new_districtCtrl.touched)}">
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="addCheck == true">
            <div class="col-xl-6" style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
              State: <span style="color:red;">*</span>
            </div>
            <div class="col-xl-6">
              <mat-form-field style="width:220px !important"> 
                <input   matInput formControlName="new_stateCtrl" [ngClass]="{'form-control-danger': secondForm.controls.new_stateCtrl.invalid && (secondForm.controls.new_stateCtrl.dirty || secondForm.controls.new_stateCtrl.touched)}">
              </mat-form-field>
            </div>
          </div>
          <div>
          <button nbButton [disabled]="secondForm.valid===false" hero status="primary" *ngIf="addCheck == true" (click)="save_add()">save</button>
          </div>
      </form>
  <!--Alternate address for couriered-->
        <div class="row" style="margin-bottom:7px">
          <div class="col-md-3">Amount</div>
          <div class="col-md-9">
            <input type="text" nbInput fullWidth placeholder="Amount"  ngModel="{{value}}" [readonly]="true">
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="row">
        <div class="col-md-3"></div>
        </div>
        <div class="col-md-6">
        <button nbButton hero status="primary" (click)="dismiss()">Close</button>
        <button *ngIf="paymentbutton === true && add_validation === true" nbButton hero status="primary" (click)="payment()">Proceed For Payment</button>
      </div>
      </nb-card-footer>
    </nb-card>
    <form id="nonseamless" method="post" name="redirect" action="{{secureUrl}}"> <input type="hidden" id="encRequest" name="encRequest" value="{{encRequest}}"><input type="hidden" name="access_code" id="access_code" value="{{accessCode}}"></form>

    <div>


    </div>
    `,
    })
export class SecondPaymentComponent {
     @Input() userdata: any;
     @Input() amount : any;
     @Input() id : any;
     @Input() edudata:any;
     @Input() app_id :any;
    // @Input() collegeConfirmation;
    notes;
    addNote: boolean = false;

  accessCode: any;
  secureUrl: any;
  encRequest: any;
  value: any;
  type: any;
  secondForm: FormGroup;
  paymentbutton: boolean;
  address_id: string;
  add_validation: boolean;
   addresssobj:{addtype:any,address:any}
   addressarr:[]
  other: boolean;
  addCheck: boolean;
  areaLocation: any;
  other_add: any;
  addresses: any;
  editAreaLocation: boolean;
    constructor(protected ref: NbDialogRef<SecondPaymentComponent>,
      private router : Router,
      private authService: NbAuthService,
      protected api : ApplicationApi,
      private toastrService: NbToastrService,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      ) {

    }
    ngOnInit(): void {
  
      
      this.api.getOtherAddress(this.id,this.address_id).subscribe(data=>{
        if(data['status']==200){
          this.other_add = data['data'];
        }
       })
    this.secondForm = this.fb.group({
      new_addressCtrl : [''],
      new_cityEditCtrl : [''],
      new_cityCtrl : [''],
      new_districtCtrl : [''],
      new_stateCtrl : [''],
      new_pincodeCtrl : ['']
    })
    this.onChange('Normal')
    }
    onChange(event){
       this.type=event
      this.api.getamountreturn(this.type).subscribe(data => {
        if (data['status'] == 200) {
            this.value=data['data']
            this.paymentbutton=true; 
            if(this.address_id != '' && this.address_id != null){
              this.add_validation = true;// 
            }
            // 

        } else {
          this.toastrService.danger('Error : Saving Details', 'Status');
        }
      });
    }

    onSelectaddress(event,add){
      
      // 
      // if(event){
        if(event == 0){
          this.other = true;
          this.add_validation = false;
        }else{
          
          
          this.addresssobj ={
            addtype:'return',
            address: add
          }
          
          
         
          
          this.other = false;
          this.addCheck = false; 
          this.add_validation = true;
        }
      // }
    }

    onChangeAreaLocation(location){ 
      this.areaLocation = location.office_name;
      var district = location.district_name;
      var state = location.state_name;
      
      this.secondForm.patchValue({
        new_districtCtrl : district
      })
      this.secondForm.patchValue({
        new_stateCtrl : state
      })
      this.secondForm.patchValue({
        new_cityEditCtrl : ''
      })
    }

    altaddCheck(event) {
      if(event.target.checked == true){
        this.addCheck = true;
        // this.alt_address = true;
        this.secondForm.controls.new_addressCtrl.setValidators([Validators.required]);
        this.secondForm.controls.new_addressCtrl.updateValueAndValidity();
        this.secondForm.controls.new_pincodeCtrl.setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(5)]);
        this.secondForm.controls.new_pincodeCtrl.updateValueAndValidity();
        this.secondForm.controls.new_districtCtrl.setValidators([Validators.required]);
        this.secondForm.controls.new_districtCtrl.updateValueAndValidity();
        this.secondForm.controls.new_stateCtrl.setValidators([Validators.required]);
        this.secondForm.controls.new_stateCtrl.updateValueAndValidity();
        this.secondForm.get('new_addressCtrl').enable();
        this.secondForm.get('new_pincodeCtrl').enable();
        this.secondForm.get('new_districtCtrl').enable();
        this.secondForm.get('new_stateCtrl').enable();
      }else{
        this.addCheck = false;
        // this.alt_address = false;
        this.secondForm.controls.new_addressCtrl.setValidators([]);
        this.secondForm.controls.new_addressCtrl.updateValueAndValidity();
        this.secondForm.controls.new_pincodeCtrl.setValidators([]);
        this.secondForm.controls.new_pincodeCtrl.updateValueAndValidity();
        this.secondForm.controls.new_stateCtrl.setValidators([]);
        this.secondForm.controls.new_stateCtrl.updateValueAndValidity();
        this.secondForm.controls.new_districtCtrl.setValidators([]);
        this.secondForm.controls.new_districtCtrl.updateValueAndValidity();
        this.secondForm.get('new_addressCtrl').disable();
        this.secondForm.get('new_pincodeCtrl').disable();
        this.secondForm.get('new_districtCtrl').disable();
        this.secondForm.get('new_stateCtrl').disable();
       }
    }

    save_add(){// 
   // 
      var newAddress={
        new_address:this.secondForm.controls.new_addressCtrl.value,
        new_pincode:this.secondForm.controls.new_pincodeCtrl.value,
        new_city:this.secondForm.controls.new_cityEditCtrl.value?this.secondForm.controls.new_cityEditCtrl.value:this.areaLocation,
        new_district:this.secondForm.controls.new_districtCtrl.value,
        new_state:this.secondForm.controls.new_stateCtrl.value,
        user_id : this.id
      };  //
      
    var addAlterAdd = this.api.addAlternateAddress(newAddress,this.id,this.address_id);
    addAlterAdd.subscribe(data =>{
      if(data['status'] == 200){ // 
        if(data['id']!=undefined || data['id']!=''){
          // this.selected = true;
          this.add_validation = true;
          this.address_id = data['id'];
          // this.amountPayment = data['data'];
          if(this.value != null){ //
            this.paymentbutton = true;
          }      
          // this.quick_message = data['quick_message'];
        }
      }else{
        // this.errormessage = true;
      }
    },
    err=>{
      // 
  
    // if(err){
    //   this.errormessage = true;
    //   this.selected = false;
    // }
    })
    }

    dismiss() {
      this.ref.close();
      }
      async payment(){

        // this.loading = true;
        var payment = await this.api.secondpaymentrequests(this.value,'',this.userdata,this.type,this.addresssobj,this.edudata,this.app_id);
        payment.subscribe(
          data => {
            this.accessCode = data['data']['accessCode'];
            this.secureUrl = data['data']['secureUrl'];
            this.encRequest = data['data']['encRequest'];
            setTimeout(function(){
             this.loading = false;
              var myForm = <HTMLFormElement>document.getElementById('nonseamless');
              myForm.submit();
            }, 1000);
          },
            error => {
                
            }
        );
      }
    // addNotes(){
    //     this.addNote = true;
    //     this.notes = this.notes_area;
    //   }

    // saveNotes(){
    //     this.api.saveNotes(this.app_id,this.notes).subscribe(data=>{
    //       this.notes_area = data['data'];
    //       this.toastrService.show('Succesfully Saved.','Success');
    //       this.ref.close();

    //     })
    //   }


    // setCollegeConfirmation(event){

    //     this.api.setCollegeConfirmation(this.app_id,event.checked).subscribe(data=>{
    //       if(data['status'] == 200){
    //         if(data['collegeConfirmation'] == true){
    //           this.collegeConfirmation = true
    //         }
    //         this.toastrService.success('College Confirmation updated','',{
    //           duration :3000
    //         })
    //       }else{
    //         if(data['collegeConfirmation'] == null){
    //           this.collegeConfirmation = false
    //         }
    //         this.toastrService.danger('Something went wrong','',{
    //           duration :3000
    //         })
    //       }
    //     })

    // }

    pincodeChanges(event){
      this.secondForm.get('new_cityCtrl').enable();
      this.secondForm.get('new_districtCtrl').enable();
      this.secondForm.get('new_stateCtrl').enable();
      this.api.checkPinValues(this.secondForm.controls.new_pincodeCtrl.value)
      .subscribe(
        (data: any) => {
          if(data['status'] == 200){
            this.addresses = data['data'];
            if(this.addresses.length==0){
              this.editAreaLocation = true;
              this.secondForm.get('new_cityEditCtrl').setValidators([]);
            }else{
              this.editAreaLocation = false;
              this.secondForm.get('new_cityCtrl').setValidators([]);
              this.secondForm.get('new_districtCtrl').disable();
              this.secondForm.get('new_stateCtrl').disable();
            }	
          }else{
            this.secondForm.patchValue({
              new_cityCtrl : ''
            })
            this.secondForm.patchValue({
              new_districtCtrl : ''
            })
            this.secondForm.patchValue({
              new_stateCtrl : ''
            })
          }
      })
      
    }
}