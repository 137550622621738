import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { RegisterUserApi } from '../../@core/backend/common/api/registerUser.api';

@Component({
  selector: 'ngx-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
}) 

export class ContactUsComponent implements OnInit {
  emailIdOperational = environment.emailIdOperational;
  numberOperational = environment.numberOperational
  faqdata: any = ''
  question: any;
  answer: any;
  constructor( 
    protected api : RegisterUserApi,
  ) {
  }

  ngOnInit(): void {
    this.faq('')
    };

  faq(id) {
    this.api.faq(id).subscribe((data) => {
     this.faqdata = data["data"] ;
     if(id == null || id  == ''){
     }else{
       this.question = this.faqdata[0].question
       this.answer = this.faqdata[0].answer
     }
   }) ;    
}
}