import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
// import { SharedModule } from '../../pages/shared-authpipe.module';
import {TabViewModule} from 'primeng/tabview';
import {TableModule} from 'primeng/table';
import {NbCardModule ,NbPopoverModule, NbActionsModule, NbButtonModule, NbAlertModule, NbProgressBarModule, NbInputModule, NbCheckboxModule} from '@nebular/theme'; 
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
// import { MatFormFieldModule } from '@angular/material';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AdminPendingComponent } from './admin-pending.component'
 import { ConfirmationService} from 'primeng/api';
import { SharedModule } from '../../shared-authpipe.module';
import { DialogModule } from 'primeng/dialog';
@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AdminPendingComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    // MatFormFieldModule,
     SharedModule,
    TabViewModule,
    TableModule,
    NbCardModule,
    NbPopoverModule,
    DialogModule,
    NbActionsModule,
    NbButtonModule,
    NbCheckboxModule,
    NbAlertModule,
    // MatInputModule,
    // MatSelectModule,
    // MatIconModule,
    // MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule, 
    NbProgressBarModule,
    // MatFormFieldModule, 
    // MatProgressBarModule, 
    // MatProgressSpinnerModule,
    NbInputModule
  ],
  providers: [ConfirmationService]
})
export class AdminPendingModule { }
