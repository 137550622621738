import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ThemeModule } from '../../@theme/theme.module';
import { FirstSuccessComponent } from './FirstSuccess.component';
import { NbAccordionModule ,NbCardModule} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { BrowserModule } from '@angular/platform-browser';
@NgModule({
  imports: [
    ThemeModule,
    NgxEchartsModule,
    NbAccordionModule,
    FormsModule,
    NbCardModule,
    DialogModule,
    BrowserModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FirstSuccessComponent,
  ],
  providers: []
})
export class FirstSuccessModule {
}