<!-- <nb-card>
    <nb-card-header>

        <div class="modal-body">
            <form novalidate="" class="ng-pristine ng-invalid ng-touched"><label class="form-label2 color-theme">Enter
                    your queryyy</label><textarea placeholder="Enter your query" formcontrolname="query" id="query"
                    class="form-control form-input ng-pristine ng-invalid ng-touched"></textarea>
            
            </form>
            <button class="btn bg-theme color-white mt-3">Submit</button>
        </div>
    </nb-card-header>
</nb-card>  -->
<!-- <nb-accordion>
    <nb-accordion-item expanded=false>
     <nb-accordion-item-header>What is the process of getting Duplicate Degree Certificate?</nb-accordion-item-header>
     <nb-accordion-item-body >
        <div >
            <nb-card-body>
                <p>Go on  and register on Duplicate Degree Certificate
 .</p>
            </nb-card-body>
        </div>
    </nb-accordion-item-body>
    </nb-accordion-item>
  <br>
  <nb-accordion-item-header>How long is Duplicate Degree Certificate
 valid?</nb-accordion-item-header>
     <nb-accordion-item-body >
        <div >
            <nb-card-body>
                <p>around 6 months
                    Duplicate Degree Certificate
  is a temporary document provided by a university until a permanent
                    degree is issued and imparted on convocation. Generally, it continues to be a valid certificate
                    for around 6 months or until a degree is issued to the students. It has no expiry date per se.</p>
            </nb-card-body>
        </div>
    </nb-accordion-item-body> -->
  

     <!-- <nb-accordion>
        <nb-accordion-item expanded=false>
            <nb-accordion-item-header>How long is Duplicate Degree Certificate
 valid?</nb-accordion-item-header>
            <nb-accordion-item-body >
               <div >
                   <nb-card-body>
                       <p>around 6 months
                        Duplicate Degree Certificate
  is a temporary document provided by a university until a permanent
                        degree is issued and imparted on convocation. Generally, it continues to be a valid certificate
                        for around 6 months or until a degree is issued to the students. It has no expiry date per se.</p>
                   </nb-card-body>
               </div>
            </nb-accordion-item> 
        </nb-accordion-item-body >  -->
        <nb-card status="success" style="height: 90%;">
            <nb-card-header >
                <div style="text-align: center;">
                  Frequently Asked Questions?
                  <br>सतत विचारले जाणारे प्रश्न?
                </div>
              </nb-card-header> 
              <nb-card-body>
                <div class="modal-body">
                    <form novalidate="" class="ng-pristine ng-invalid ng-touched"><label class="form-label2 color-theme">कोणत्याही प्रश्नासाठी, कृपया आम्हाला <a href="">{{emailIdOperational}}</a> वर ईमेल करा.</label>
                            <p>किंवा आम्हाला  {{numberOperational}}वर कॉल करा.  </p>
        <!-- <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>What is the process of getting Duplicate Degree Certificate?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>Go on suuniversity.studentscenter.in and register on Duplicate Degree Certificate.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
      -->
        <!-- <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>How long is Duplicate Degree Certificate
 valid?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>around 6 months
                                            Duplicate Degree Certificate
  is a temporary document provided by a university until a permanent
                                            degree is issued and imparted on convocation. Generally, it continues to be a valid certificate
                                            for around 6 months or until a degree is issued to the students. It has no expiry date per se.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br> -->
        <!-- <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate
  important?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>The Duplicate Degree Certificate serves as documentation that the original certificate has not
                                            yet been issued. Until the final degree arrives, the certificate is essential. It can be used
                                            for future opportunities such as employment or higher education</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion> -->
        <!-- <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate and original degree same?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>A degree certificate is the official permanent certificate you get after completing your
                                            college degree. A Duplicate Degree Certificate is a temporary copy. It is useful for proving
                                            that you have completed your degree until you receive the official copy.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion> -->
        <!-- <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Can I get Duplicate Degree Certificate if I have backlogs?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>No. Degrees, be it Provisional or the Real one, are granted only after you
                                            successfully pass all the coursework without any backlog.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>What is the value of Duplicate Degree Certificate?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>The Duplicate Degree Certificate
 is treated as original degree as long as the original degree is not
                                            issued.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Can we use Duplicate Degree Certificate instead of degree certificate?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>yes, you may produce your Duplicate Degree Certificate
  until you get the final degree
                                            certificate.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate valid for abroad studies?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>As per the nature of the Duplicate Degree Certificate, you can use it until you get your degree
                                            from your university. It is free from any expiry date for use in your home country to
                                            pursue further education or catch job opportunities. However, you cannot use it to
                                            enrol in a foreign university</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate and consolidated Marksheet same?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>It is a temporary certificate until the original certificate came. By using provisional
                                            certificate then you can easily use that one to search for a job. Consolidated Marks
                                            Card (CMC) of All the Semesters looks like a marks card (colorful) containing marks of
                                            the 1st semester to 8th semester.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>How long do colleges keep certificates?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>They either keep the certificate with them or after certain years like 5 years, may have it
                                            to the university. You can directly visit your college and contact your department HOD
                                            or teachers or even visit the college office and make an application for the same</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>What is difference between migration and Duplicate Degree Certificate?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>At last, we can say that a Duplicate Degree Certificate is a temporary certificate which is given
                                            to the candidates to go for their higher studies or for a job while a Migration certificate is
                                            given to a candidate to continue their higher study or to change any university in
                                            between the course.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate
  enough for UK visa?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>Yes, they do. If you haven&#39;t received your final degree certificate yet. Best to provide
                                            the Final degree certificate if you have it already.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>What is the use of Duplicate Degree Certificate?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>A Duplicate Degree Certificate is a type of certificate issued for a temporary period of time. If
                                            you want to apply for an admission but haven&#39;t received your original degree
                                            certificate, then you can submit your Duplicate Degree Certificate and secure an
                                            admission in a college.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Can we get a Duplicate Degree Certificate online?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>Yes you can. Register on gujuratuniversity.studentscenter.in</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>When will a Duplicate Degree Certificate be issued?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>When there is a delay in receiving the final degree certificate, your institution or university will
                                            normally issue a Duplicate Degree Certificate. Depending on which college you graduated from, the time it
                                            takes to receive this temporary certificate can range from 3 to 6 months.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>What if Duplicate Degree Certificate is lost?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>If the Convocation is not over, you can lodge an FIR reporting the loss of provisional
                                            certificate, get a receipt for the FIR and again apply to the University with the
                                            requisite fee and copy of the receipt of FIR.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate and Marksheet same?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>No, they are different. Passing certificate is given to: (i) A candidate who has appeared
                                            in an examination of the Board shall be issued a statement of marks/grades.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate and transfer certificate same?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>At last, we can say that a Duplicate Degree Certificate is a temporary certificate which is given
                                            to the candidates to go for their higher studies or for a job while a Migration certificate is
                                            given to a candidate to continue their higher study or to change any university in
                                            between the course.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate and passing certificate same?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p></p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>Is Duplicate Degree Certificate and transcript same?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>This is used because the degree is not received yet. On the other hand, transcript
                                            certificate is like a marks certificate. Only the Duplicate Degree Certificate has to be
                                            submitted if asked for, nothing in place of that. Hope this helps.</p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
        
            </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion type="info">
            <nb-accordion-item expanded=false>
             <nb-accordion-item-header>What are the fees for Duplicate Degree Certificate
 ?</nb-accordion-item-header>
             <nb-accordion-item-body >
                        <nb-card>

                                <nb-card-body>
                                    <form>
                                        <p></p>
                                </form>
                                </nb-card-body>
                        </nb-card>
             </nb-accordion-item-body>
        
            </nb-accordion-item>
        </nb-accordion> -->

        <div *ngFor="let faq of faqdata; index as i;">
            <nb-accordion type="info">
              <nb-accordion-item expanded=false>
               <nb-accordion-item-header style="font-weight: bold;">{{faq.question}}</nb-accordion-item-header>
               <nb-accordion-item-body ><p>{{faq.answer}}</p> </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
            <br>
          </div>
        </form>
        </div>
        </nb-card-body>
        </nb-card>







        
        
        
        
