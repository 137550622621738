import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ThirdPartyApplicationsApi } from '../../../@core/backend/common/api/thirdPartyApplications.api';
import { SharedService } from '../../../@core/backend/common/services/shared.service';
import { User } from '../../../@core/interfaces/common/users';
import { UserStore } from '../../../@core/stores/user.store';
import { NbDialogService } from '@nebular/theme'; 
import { Firstpaymentdialog } from '../../student-verification/payment_dialog/paymentdialog';
import { Subscription } from 'rxjs';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';

@Component({
  selector: 'ngx-view-application',
  templateUrl: './view-applications.component.html',
  styleUrls: ['./view-applications.component.scss']
})
export class ViewApplicationsComponent implements OnInit {
  singleApplication:any;
  partyApplication: any = 0;
  role: string;
  user: User; 
  email: string;
  id: any;
  p2: number = 1;
  page: number = 1;
  subscriptionGroup: Subscription[]= [];
  constructor( private api: ThirdPartyApplicationsApi,
     private userStore: UserStore, 
     private router: Router,
     private sharedService: SharedService,
     private NbDialogService: NbDialogService,
     private applicationapi: ApplicationApi) { }

  ngOnInit(): void {
    if(this.userStore.getUser() == undefined){
      this.router.navigate(['auth/login']);
    }else{
        this.user=this.userStore.getUser();
        this.id=this.user.id;
        this.role=this.user.role;
        this.email=this.user.email;
       // 
       // 

        this.api.getThirdPartyApplications(this.id).subscribe(data => {
          this.partyApplication = data['data'];
         //
       })
       this.subscriptionGroup.push(
       this.sharedService.agentmessageSource.subscribe((message) => {
        //
        if(message == 'applied'){
          this.ngOnInit();
        }
    })
       )
      }

  }
  downloadDocument(){
    // var url =environment.apiUrl+`/download/?id=`+this.user.id
    // window.open(url)
  }
  viewDocument(url){

//
window.open(url, '_blank')

  
}

  downloadReceipt(){
   // 
  }
  proceedforpayment(app_id,app_email,app_name){
    this.NbDialogService.open(Firstpaymentdialog, {
      context: {
        app_id : app_id,
        app_email: app_email,
        app_name: app_name,
      }
    })
    
  }
  ngOnDestroy() {
    this.subscriptionGroup.forEach(subscription => {
      
      subscription.unsubscribe()
  })
    //this.sharedService.messageSource.unsubscribe();
  }
  editApplication(applicationId){
    
    //TODO fetch the application and display on the student-apply component
    this.applicationapi.getApplicationIdWise(applicationId).subscribe(data => {
      this.singleApplication = data['data'];   
     // 
      this.sharedService.userErrata.next(this.singleApplication);
    })
   
  }


}
