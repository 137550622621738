import { Component, OnInit } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
// import { CountriesService } from '../../@core/data/countries.service';
// import { CountriesService } from '../../@core/data/countries.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import * as printJS from 'print-js';
import {NbDialogService } from '@nebular/theme';

import { NotesComponent } from '../../user/notes.component';
import { saveAs } from 'file-saver';
import { Data } from '../../../../assets/data/data';
import { Subject } from 'rxjs';
import { UserStore } from '../../../@core/stores/user.store';
import { User, UserData } from '../../../@core/interfaces/common/users';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../../../environments/environment.prod';
import { StudentimageviewComponent } from '../../user/studentimageview/studentimageview.component';

// import { Data } from '../../shared/data';
@Component({
  selector: 'ngx-admin-print',
  templateUrl: './admin-print.component.html',
  styleUrls: ['./admin-print.component.scss']
})
export class AdminPrintComponent implements OnInit {
  Countries: any [];
  p: number = 1;
  public filterText: string;
  public filterPlaceholder: string;
  
  public filterInput = new FormControl();
  selectedYear ='2019'
  protected readonly unsubscribe$ = new Subject<void>();

  studentdata: any;
  active: any;
  searchForm:FormGroup;
  name : string;
  CourierUploadForm : FormGroup;
  user: any;
  id: any;
  role: string;
  adminemail: any;
  route: any;
  usersData: any;
  email : string;
  isLoadingResults: boolean = false;
  studentLength : any;
  app_id: any;
  applicationdata: any;
  notes: any;
  serverUrl=environment.courier_excel_sheets;
   certiid = [];
   display: boolean=false;
   updatemerge: any;
  toastrService: any;
  loadingXL: boolean;
  reason_value: boolean;
  user_id: any;
  totalLength: any;
  showprintdate: boolean;
  header: string;
  printdones: boolean;
  showreason: boolean;
  servicetype: any;
  headers: string;
  email_admin: any;
  courier: boolean;
  mails: boolean;
  selected_value =[];
  constructor(  private api: ApplicationApi,
    private authService : NbAuthService,
    private router : Router,
    private userStore: UserStore,
    private usersService: UserData,
    public dialogservice: NbDialogService,
    // protected countries :CountriesService,
    public toasterService: NbToastrService,
    protected globalVar:Data,
    private formBuilder:FormBuilder,
    private confirmationService: ConfirmationService


    ) {

   }

  ngOnInit() {'this.certiid'
    this.filterText = "";
    this.filterPlaceholder = "Local Search";
    this.refresh(null,null,null);
    
    this.searchForm = this.formBuilder.group({
      nameCtrl:[''],
      emailCtrl:[''],
      idCtrl : [''],
      courier:[''],
      trackinid:[''],
      pickdatectrl:['']

    })
    this.CourierUploadForm = this.formBuilder.group({
  
      financeUpload:['']
    })
    if(this.userStore.getUser() == undefined){      
      this.usersService.getCurrentUser().pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
            this.id=this.user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;
            this.email_admin = user.data.email;
            
      })
    
          this.userStore.onUserStateChange()
          .pipe(
            takeUntil(this.unsubscribe$),
          )
          .subscribe((user: User) => {
            this.id=user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;    
            this.email_admin =  this.user.email;    

          });
    
      }else{        
        this.user=this.userStore.getUser();
        this.id=this.user.id;
        this.role=this.user.role;
        this.email_admin =  this.user.email;    

      }
      var userId=this.route.snapshot.queryParamMap.get('userId');
      
      
        this.api.getAdminSideDetails(userId).subscribe(data => {
          this.usersData= data['data'][0] ? data['data'][0] : null;    
          });

  }

  toggle(event,user_id,id){
    
    
    if(event==true){
      
      
      this.selected_value.push({
        user_id:user_id,
        app_id:id
      })

    }else if(event==false){
      
      
      this.selected_value.splice(this.selected_value.findIndex(data => data.user_id === user_id) , 1)

    }
    
  }
  megecertificate(megecertificate){
    
    this.api.mergecerti(megecertificate).subscribe((data)=>{
      if(data['status']==200){
        this.toasterService.success('Email Sent Succesfully','Success',{preventDuplicates:true});

        this.refresh(null,null,null);
      }

    })
    
  }
  ok(updatemerge){
    
    
this.api.mergecerti(updatemerge).subscribe((data)=>{
      if(data['status']==200){
        this.toasterService.success('Email Sent Succesfully','Success',{preventDuplicates:true});
        this.display=false

        this.refresh(null,null,null);
      }

    })
  }
  onChange(value,app_id){
    
    this.api.colornagnge(value,app_id).subscribe((response)=>{
      
      if(response['status']=='200'){
        this.refresh(null,null,null);

      }
      
  
    })
    
  }
  handleClick(user_id,app_id) {


    this.router.navigate(['pages/adminView'],{queryParams:{userId : user_id, app_id : app_id, viewFrom : 'print'}});
  }
  pageChanged(p){
    this.p = p;
    this.globalVar.ViewpageValue=p;
    this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value);
  }

  search(){
    if(this.searchForm.controls.idCtrl.value || this.searchForm.controls.nameCtrl.value || this.searchForm.controls.emailCtrl.value){
      this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value);
    }else{
      alert("Please specify any of the search criteria")
    }
  }
  // addNotes(app_id){
    
  //   
  //   this.dialogservice.open(NotesComponent, {
  //     context: {

  //          app_id: app_id,
  //          type:'notes'
  //     },
  //   }).onClose.subscribe(data=>{
  //     this.getapplication()
  //   })

  // }
  addNotes(userid,app_id){
    
    
    this.dialogservice.open(NotesComponent, {
      context: {

           app_id: app_id,
           type:'notes',
           userid:userid,
           email_admin :this.email_admin
      },
    }).onClose.subscribe(data=>{
      this.getapplication()
    })

  }
  getapplication(){
    this.api.getapplication(this.app_id).subscribe(data => {
      if(data['status'] == 200){
        this.applicationdata=data['data']
        this.notes= this.applicationdata[0].notes ? this.applicationdata[0].notes : ''
        
       
      }else{
        this.toastrService.danger('Error Occured','Error',{preventDuplicates: true});
      }
    });
   }


  onUpload(event) {
    // this.loading1 = true;
    const reader = new FileReader();
    if(event.files && event.files.length) {
      const [file] = event.files;
      reader.readAsDataURL(file);
      
      
      
      var json =event.originalEvent.body;
      var data = json.data; // or json["Data"]
      var status = json.status; // or json["Data"]
      if(status == 200){
        this.api.downloadFilesExcel(data)
        .subscribe(data => {
          if(data['status']==200){

            saveAs(data['data'], 'Print.xlsx'); 

          }
          
          // this.loading1 = false;
        });
        
      }
    }
  }
  onBeforeSend(event) {
    // this.loading1 = true;
  }
  refresh(application_id,student_name, email){
    this.isLoadingResults = true;
    // 
    this.api.getAll_PrintApplications(this.p,application_id,student_name,email,'print').subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata = data['items'];
      
      
      this.studentLength = data['total_count'];
      this.totalLength = this.studentLength.totalLength;
      
      

      this.studentLength = data['total_count'];
      // this.active=data['counts'];
    })
    this.filterInput
      .valueChanges
      // .debounceTime(200)
      .subscribe(term => {
       this.filterText = term;
    });
  }
  mailcertificate(user_id,id,email,file_path,email_admin){




if(email_admin.includes('@edulab.in')){
      this.toasterService.warning("you don't have access to verify application");
}
else{
    this.confirmationService.confirm({
      message: 'You have to mail the certificate to student then click yes',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.api.mail(user_id,id,email,file_path,email_admin).subscribe((response)=>{
          if(response['status']=='200'){
            this.refresh(null,null,null)
          }
          
        })      },
      reject: () => {
        
      }
  });
}
  }
  downloadExcel(type){
    this.loadingXL = true;
    this.api.downloadExcel(type).subscribe((response)=>{
     if(response[`status`] == 200){
         this.api.downloadFilesExcel(response[`data`])
        .subscribe(data => {
          this.loadingXL = false;
          saveAs(data, 'Print.xlsx'); 
        });
      }
    })
  }
  
  reason_add(app_id,user_Id){
    
    var email =this.email_admin 
    
    if(email.includes('@edulab.in')){
      this.reason_value=false
      this.toasterService.warning("you don't have access to back the application")
    }
    else{
    this.showreason=true

    this.reason_value=true
    this.user_id = user_Id;
    this.app_id = app_id;
    }
  }
 regenerate(reg_reason) {
    // 
    // 
    
    
    
   
        this.api.regeneratePdf(this.app_id , this.user_id , reg_reason,this.email_admin).subscribe((data: any) => {
          if(data['status'] == 200){
            
            this.toasterService.success(data['message'],'Success',{preventDuplicates:true});
            this.reason_value = false;

            this.refresh(null,null,null);        
            }else if(data['status'] == 400){
            this.toasterService.danger(data['message'],'Error',{preventDuplicates:true});
            this.refresh(null,null,null);          }      
            err => console.error(err);
        },
        error=>console.error("Error", error)
        )
  
  }
  save(){
    
 

  }

  printJS(user_id, id,file_path,type) {
    
    this.api.getcertificate(id, user_id,).subscribe(data =>{
      if(data['status']==200){
        var file_path=data['outputMergefile']
        this.refresh(null,null,null);
        this.ngOnInit()
      }
      
    })
  }
  viewCertificate(user_id, id,file_path,type){
    
    
    setTimeout(() => {  
          this.dialogservice.open(StudentimageviewComponent, {
            context: {
              filename:file_path,
                  type:type,
                  
            },
          });
        }, 5000);
       
  }


  printJSAddress(user_id,id){
    
    this.api.getAddressAdmin(user_id,id).subscribe(data =>{
      if(data['status']==200){
        var address=data['address_path']
        
        printJS({
          // size : 'A4',
          printable: address,
          type:'pdf', 
          showModal:true
        })
        
        
      }
      
    })
  }
  printdone(id,user_id,name,email,servicetype){

    this.api.sentoedulab(id , user_id ,'','','',this.email_admin ).subscribe((data: any) => {
      if(data['status'] == 200){
        
        
        this.searchForm.reset()
        this.reason_value=false
        this.toasterService.success('Saved Succesfully','Success',{preventDuplicates:true});
        this.refresh(null,null,null);        
        }else if(data['status'] == 400){
        this.toasterService.danger(data['message'],'Error',{preventDuplicates:true});
        this.refresh(null,null,null);          }      
        err => console.error(err);
    },
    error=>console.error("Error", error)
    )


    
 

  }

}
