import { Component, OnInit } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import {  Router } from '@angular/router';
// import { CountriesService } from '../../@core/data/countries.service';
// import { CountriesService } from '../../@core/data/countries.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Data } from '../../../../assets/data/data';
import { StudentimageviewComponent } from '../../user/studentimageview/studentimageview.component';
import { UserStore } from '../../../@core/stores/user.store';
import { User, UserData } from '../../../@core/interfaces/common/users';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// import { Data } from '../../shared/data';
@Component({
  selector: 'ngx-admin-emailed',
  templateUrl: './admin-emailed.component.html',
  styleUrls: ['./admin-emailed.component.scss']
})
export class AdminEmailedComponent implements OnInit {
  Countries: any [];
  p: number = 1;
  public filterText: string;
  public filterPlaceholder: string;
  public filterInput = new FormControl();
  selectedYear ='2019'
  studentdata: any;
  active: any;
  searchForm:FormGroup;
  name : string;
  email : string;
  protected readonly unsubscribe$ = new Subject<void>();

  isLoadingResults: boolean = false;
  studentLength : any;
  student_name: any;
  totalLength: any;
  email_admin: any;
  user: any;
  role: string;
  id: any;
  constructor(  private api: ApplicationApi,
    private authService : NbAuthService,
    private router : Router,
    private userStore: UserStore,
    private usersService: UserData,

    public dialogservice: NbDialogService,
    // protected countries :CountriesService,
    public toasterService: NbToastrService,
     protected globalVar:Data,
    private formBuilder:FormBuilder,) {

   }

  ngOnInit() {
    
    this.filterText = "";
    this.filterPlaceholder = "Local Search";
    this.refresh(null,null,null);
    this.searchForm = this.formBuilder.group({
      nameCtrl:[''],
      emailCtrl:[''],
     idCtrl : [''],
    })

    if(this.userStore.getUser() == undefined){      
      this.usersService.getCurrentUser().pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        
        
            this.id=this.user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;
            this.email_admin = user.data.email;
            

            
      })
    
          this.userStore.onUserStateChange()
          .pipe(
            takeUntil(this.unsubscribe$),
          )
          .subscribe((user: User) => {
            
            
            this.id=user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;    
            this.email_admin = this.user.email;

          });
    
      }else{        
        this.user=this.userStore.getUser();
        this.id=this.user.id;
        this.role=this.user.role;
        
        this.email_admin = this.user.email;

    
      }
  }

  handleClick(user_id,app_id) {


    this.router.navigate(['pages/adminView'],{queryParams:{userId : user_id, app_id : app_id, viewFrom : 'emailed'}});
  }
  pageChanged(p){
    this.p = p;
    this.globalVar.ViewpageValue=p;
     this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value);
  
  }

  search(){
    if(this.searchForm.controls.idCtrl.value || this.searchForm.controls.nameCtrl.value || this.searchForm.controls.emailCtrl.value){
      this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value);
    }else{
      alert("Please specify any of the search criteria")
    }
  }
  regenrate(id,user_id){
    this.api.senttoprint(id ,user_id ).subscribe((data: any) => {
      if(data['status'] == 200){
        
        this.toasterService.success(data['message'],'Success',{preventDuplicates:true});

        this.refresh(null,null,null);        
        }else if(data['status'] == 400){
        this.toasterService.danger(data['message'],'Error',{preventDuplicates:true});
        this.refresh(null,null,null);          }      
        err => console.error(err);
    },
    error=>console.error("Error", error)
    )
  }
  refresh(application_id,student_name, email){
    this.student_name=student_name;
    this.isLoadingResults = true;
    // 
    this.api.getAll_emailedApplications(this.p,application_id,student_name,email).subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata = data['items'];

      this.studentLength = data['total_count'];
      this.totalLength = this.studentLength.totalLength;

      // this.active=data['counts'];
    })
    this.filterInput
      .valueChanges
      // .debounceTime(200)
      .subscribe(term => {
       this.filterText = term;
    });
  }
  printJS(id, user_id,file_path) {
    
    
    // var ff = "C:\Users\info\OneDrive\Desktop\Convocation\guConvocationServer\public\certificate\89\89_Certificate.pdf"
    // var file_path = file_path;
    setTimeout(() => {  
      this.dialogservice.open(StudentimageviewComponent, {
        context: {
          filename:file_path
              // docid : trasncriptid,
              // app_id :  app_id,
              // type:type,
              // email_admin : email_admin,
              // userid : this.userId
        },
      });
    }, 5000);
  }

  //send to print by edulab
  sendToPrintByEdulab(app_id){
    var email= this.email_admin
    
    if(email.includes('@edulab.in')){
      this.toasterService.warning("you don't have access to back the application")
    }
    else{
    let tracker='printedulab';
    this.api.sendToPrintByEdulab(app_id,tracker).subscribe((res:any)=>{
      if(res.status === 200){
        this.toasterService.success(res.message,'Success',{preventDuplicates:true});
        this.refresh(null,null,null);
      }else{
        
        this.toasterService.danger(res.message,'Error',{preventDuplicates:true});
      }
    })
  }
}

}
