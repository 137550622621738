import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'ngx-studentimageview',
  templateUrl: './studentimageview.component.html',
  styleUrls: ['./studentimageview.component.scss']
})
export class StudentimageviewComponent implements OnInit {
  @Input() type: any;
  user_type: string;
  rotationAngle = 0;
  @Input() filename: string;
  constructor(
    protected location: Location,
    private route: ActivatedRoute,
    private router: Router,
    protected ref: NbDialogRef<StudentimageviewComponent>,

  ) { }

  ngOnInit(): void {
    // this.filename=this.route.snapshot.queryParamMap.get('filename');
    // this.user_type=this.route.snapshot.queryParamMap.get('user_type');
    

    

    // this.type=this.route.snapshot.queryParamMap.get('type');
    var game=document.getElementById("viewpdf");
    game.setAttribute("src",this.filename);

    




  }
  dismiss(){
    // this.location.back();
    this.ref.close();

   }
   rotateClockwise() {
    this.rotationAngle += 90;
  }
  rotateCounterclockwise() {
    this.rotationAngle -= 90;
  }

}
