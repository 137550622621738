import { Component, Input} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Router ,ActivatedRoute} from '@angular/router';
import { ApplicationApi } from '../../@core/backend/common/api/application.api';
import {  NbToastrService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User, UserData } from '../../@core/interfaces/common/users';

@Component({
    selector: 'nb-dialog',
    template: `<nb-card [style.width.px]="450"  [style.height.px]="300" status="success">
  <nb-card-header>
    <div class="row">
      <div class="col-md-8">
        Notes:
      </div>
      <div class="col-md-4" style="text-align:right">

      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="statementForm"  class="step-container">
    <textarea cols="30" rows="5"  [ngClass]="{'form-control-danger': statementForm.controls.statementCtrl.invalid && (statementForm.controls.statementCtrl.dirty || statementForm.controls.statementCtrl.touched)}" formControlName="statementCtrl" placeholder="Please enter the message" class="form-control" [(ngModel)]="notes">
    </textarea>
    <div *ngIf="form_submitted && statementForm.controls.statementCtrl.invalid">
      <div *ngIf="statementForm.controls.statementCtrl.required">Message is required</div>
    </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="row" >
      <div class="col-md-5"></div>
      <div class="col-md-7"><button nbButton hero status="primary" (click)="ok()">OK</button></div>
    </div>
  </nb-card-footer>
</nb-card>
`,
    })
    export class NotesComponent  {
      protected readonly unsubscribe$ = new Subject<void>();

        @Input() app_id:any;
        @Input() userid:any;
        @Input() docid:any;
        @Input() type:any;
        @Input() user_id:any;
        @Input() email_admin:any;
        statementForm: any;
        form_submitted:boolean=false;
  userStore: any;
  usersService: any;
  id: any;
  user: any;
  role: any;
  email: any;
  applicationdata: any;
  notes: any;


        constructor(protected ref: NbDialogRef<NotesComponent>,
          protected api : ApplicationApi,
          private fb: FormBuilder,
          private route: ActivatedRoute,
          private toastrService: NbToastrService,) {
        }

        ngOnInit() {
       
          
          this.buildstatementForm();
          this.getapplication()

        }

        private buildstatementForm() : void{
          this.statementForm = this.fb.group({
            statementCtrl :['',[Validators.required]],
          });
        }

        close(){
          this.ref.close();
        }
        ok(){       
          
             
                // var applyingFor =this.affiliationData[0].applied_for;
                this.api.setErrata(this.app_id,this.statementForm.controls.statementCtrl.value , this.docid,this.type,this.email_admin,this.userid).subscribe(data => {
                  if(data['status'] == 200){
                    this.toastrService.success('Note Added Successfully','Success',{preventDuplicates: true});
                    this.ref.close();
                  }else{
                    this.toastrService.danger('Error Occured','Error',{preventDuplicates: true});
                  }
                });
        }
        getapplication(){
          this.api.getapplication(this.app_id).subscribe(data => {
            if(data['status'] == 200){
              this.applicationdata=data['data']
              this.notes= this.applicationdata[0].notes ? this.applicationdata[0].notes : ''
              
             
            }else{
              this.toastrService.danger('Error Occured','Error',{preventDuplicates: true});
            }
          });
         }
        // ok(){
        //   this.statementForm.controls.statementCtrl.markAsDirty();
        //   this.form_submitted = true;
        //   if (this.statementForm.valid) {
        //     this.api.updateNoteAndApplication(this.app_id,this.user_id,this.event.checked,this.type,this.statementForm.controls.statementCtrl.value).subscribe(data=>{

        //       if(this.type == 'partialApproved'){
        //         var result = {
        //           data : data['partialApproved'],
        //           notes : data['notes']
        //         }
        //         this.ref.close(data);
        //       }else if(this.type == 'marksheet_incomplete'){
        //         var result = {
        //           data : data['marksheet_incomplete'],
        //           notes : data['notes']
        //         }
        //         this.ref.close(data);
        //       }
        //     });
        //   }
        // }



      }