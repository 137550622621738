<nb-card>
  <nb-card-header status="success">
    <label style="font-size: 140%;">Student Management</label>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="searchForm" class="row">
      <div class="form-group col-xl-5">
        <label>Name</label><br>
        <input class="input" formControlName="nameCtrl" [(ngModel)]="name" placeholder="name"
          [ngClass]="{'form-control-danger': searchForm.controls.nameCtrl.invalid && (searchForm.controls.nameCtrl.dirty || searchForm.controls.nameCtrl.touched)}">
      </div>

      <div class="form-group col-xl-5">
        <label>Email ID</label><br>
        <input class="input" formControlName="emailCtrl" [(ngModel)]="email" placeholder="email"
          [ngClass]="{'form-control-danger': searchForm.controls.emailCtrl.invalid && (searchForm.controls.emailCtrl.dirty || searchForm.controls.emailCtrl.touched)}">
      </div>

      <div class="col-xl-2">
        <button (click)="search()">Search</button>
      <!-- <button nbButton (click)="create()">Search</button> -->
      </div>
    </form>


    <br>
    <div class="row">
      <div class="col-lg-5">
          <input class="input" type="text" [value]="filterText" [placeholder]="filterPlaceholder"
            [formControl]="filterInput" />
          <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText=''">
            <mat-icon>close</mat-icon>
          </button>
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>
      <!-- <div class="col-lg-3 ">
          <mat-form-field>
            <mat-select placeholder="select Year" [(ngModel)]="selectedYear" name="selectedYear" id="yearCtrl"
              (ngModelChange)="filterYear($event)">
              <mat-option value="2019">2019-2020</mat-option>
              <mat-option value="2020">2020-2021</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
    </div>

    <div class="wrapper">
      <h5><b>Total student count: {{studentLength?.totalLength}} </b></h5><br /><br />
      <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->
      
      <table id="selectedColumn">
        <thead>
          <tr>
            <th>Registered Date</th>
            <th>Full Name</th>
            <th>Email Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
          *ngFor="let student of studentdata  | filter:{name:filterText,email:filterText} : false   | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
            <td>
              {{student?.registered_on}}
            </td>
            <td>
              {{student?.name}}
            </td>
            <td>
              {{student?.email}}
            </td>
            <td>
              <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                (click)="viewMore('studentmgmt',student.id,student?.user_type,student?.course_id);"></i><br>
              <button nbButton outline size="small"
                *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                (click)="verifyOtp('0', '0', student.id);">Verify</button>
              <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                (click)="status('inactive',student.id);">inactive</button>
              <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                (click)="status('active',student.id);">active</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="studentLength?.totalLength == 0">
        <span style="color : red; font-size: 18px;">No Data Available</span>
      </div>
    </div>
    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
  </nb-card-body>
</nb-card>