
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationApi } from '../../@core/backend/common/api/application.api';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import{saveAs} from 'file-saver'
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'firstsuccess',
  template: `
  <div class="row">
    <div class="col-xxxl-8 col-xxl-8 col-lg-7 col-md-8">
      <nb-card>
        <nb-card-header>
          <h1>PAYMENT SUCCESSFUL</h1>
        </nb-card-header>
        <nb-card-body>
          <div class="row">
            <h2>
              Thank you! Your transaction is successful
            </h2>
          </div>
          <div class="row">
            <h2>
              Your transaction id is {{ transaction_id }}
            </h2>
          </div>
          <div class="row">
            <h3>
              Payment Details :-
            </h3>
          </div>
          <div class="row">
            <h4>
              Application Id: {{ application_id }}
            </h4>
          </div>
          <div class="row">
            <h4>
              Transaction ID: {{ transaction_id }}
            </h4>
          </div>
          <div class="row">
            <h4>
              Amount        : {{ payment_amount }}
            </h4>
          </div>
          <div class="row">
            <h4>
              Payment Status: {{ payment_status }}
            </h4>
          </div>
       
        </nb-card-body>
      </nb-card>
    </div>
  </div>
  <p-dialog [(visible)]="displayNo" [modal]="true" [responsive]="true" [style]="{width: '900px', minWidth: '500px'}"
  [minY]="70" [baseZIndex]="10000">
  <form [formGroup]="feedfack">
  <nb-card status="success" class="col-lg-12">
    <nb-card-header>We would like your feedback to improve our website</nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          How satisfied are you with the website ?
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-3">
          <input type="radio"  name="satisfy" id="Unsatisfy" value="Unsatisfy"
            formControlName="satisfy" [checked]="website_satisfy == 1" /> Unsatisfy
        </div>
        <div class="col-lg-3">
          <input type="radio"  name="satisfy" id="can_improve"
            value="can_improve" formControlName="satisfy" [checked]="website_satisfy == 2" /> Can
          Improve
        </div>
        <div class="col-lg-3">
          <input type="radio"  name="satisfy" id="good" value="good"
            formControlName="satisfy" [checked]="website_satisfy == 3" /> Good
        </div>
        <div class="col-lg-3">
          <input type="radio"  name="satisfy" id="best" value="best"
            formControlName="satisfy" [checked]="website_satisfy == 4" /> Best
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-12">
          Will you recommend this website to others?
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-3">
          <input type="radio"  name="recommend" id="recommend_Yes"
            value="Yes" formControlName="recommend" [checked]="website_recommend == 1" /> Yes
        </div>
        <div class="col-lg-3">
          <input type="radio" name="recommend" id="recommend_No" value="No"
            formControlName="recommend" [checked]="website_recommend == 2" /> No
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-12">
          Are you satisfied with technical team?
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-3">
          <input type="radio"  name="staff" id="staff_Unsatisfy"
            value="Unsatisfy" formControlName="staff" [checked]="staff_satisfy == 1" /> Unsatisfy
        </div>
        <div class="col-lg-3">
          <input type="radio"  name="staff" id="staff_can_improve"
            value="can_improve" formControlName="staff" [checked]="staff_satisfy == 2" /> Can
          Improve
        </div>
        <div class="col-lg-3">
          <input type="radio"  name="staff" id="staff_good" value="good"
            formControlName="staff" [checked]="staff_satisfy == 3" /> Good
        </div>
        <div class="col-lg-3">
          <input type="radio" name="staff" id="staff_best" value="best"
            formControlName="staff" [checked]="staff_satisfy == 4" /> Best
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-12">
          Did you experience any problems with our services?
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-3">
          <input type="radio"  name="experience" id="yes" value="yes"
            formControlName="experience" (change)="stateProblem('yes');"
            [checked]="experience_problem == 1" /> Yes
        </div>
        <div class="col-lg-3">
          <input type="radio"  name="experience" id="no" value="no"
            formControlName="experience" (change)="stateProblem('no');"
            [checked]="experience_problem == 2" /> No
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12" *ngIf="showExperience ">
          <textarea nbInput fullWidth shape="round"
            formControlName="exp_prob"
          ></textarea>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-6">
          Any Suggestions?(optional)
        </div>
        <div class="col-lg-6">
          <textarea nbInput fullWidth shape="round" formControlName="suggestion"></textarea>
        </div>
      </div>
    
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="info" (click)="saveFeedback()">save</button>
    </nb-card-footer>
  </nb-card>
</form>
</p-dialog>

  `,
})
export class FirstSuccessComponent  {
  website_satisfy: number;
  website_recommend: number;
  staff_satisfy: number;
  experience_problem: number;
  feedfack: FormGroup;


  showExperience: boolean;
  displayNo: boolean=true;
  feedback_message: string;
  validations_flag: boolean;
  user: any;
  feedbackshow: any;
  checkErrata: any;
  getApplicationdata: any;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: NbAuthService,
    private formBuilder: FormBuilder,
    protected api : ApplicationApi) {
      this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        this.user_id = token.getPayload()['id'];
      });
  }
  transaction_id;
  application_id;
  payment_amount;
  payment_status;
  payment_date_time;
  user_id;
  enrollment_no;
  order_id;
  async ngOnInit() {
    this.order_id = this.route.snapshot.queryParamMap.get('order_id');
    var PaymentDetails = await this.api.PaymentDetails(this.route.snapshot.queryParamMap.get('order_id'))
    PaymentDetails.subscribe(
      data => {
        this.transaction_id = data['data']['transaction_id'];
        this.application_id = data['data']['application_id'];
        this.payment_amount = data['data']['payment_amount'];
        this.payment_status = data['data']['payment_status'];
        this.payment_date_time = data['data']['payment_date_time'];
        this.user_id = data['data']['user_id'];

        this.order_id = this.route.snapshot.queryParamMap.get('order_id')
        this.enrollment_no = data['data']['enrollment_no'];
        if(data['status']=='200'){
      //     this.api.generatepdfform(this.user_id, this.application_id,'Form').subscribe(data=>{
            

      //       if(data['status'] == 200){

      //       }
      // })
      //  this.pdfChallan(this.user_id,this.application_id)
       this.merge(this.user_id,this.application_id)
       this.marksheet(this.application_id)

        }
      }


    )
    this.feedfack = this.formBuilder.group({
      satisfy: [''],
      recommend: [''],
      staff: [''],
      experience: [''],
      exp_prob: [''],
      suggestion: [''],
    });

    this.getApplicationdata();
    this.checkErrata();
    this.feedbacks();
    this.feedbackshow();

 
  }

async merge(user_id,app_id){
  var MergeDocuments = await this.api.MergeDocuments(app_id,user_id);
  MergeDocuments.subscribe(
    merge => {
     if(merge['status']==200){
      
     }       
    }
  )
}

async marksheet(app_id){
  var MergeDocuments = await this.api.MergeMarksheet(app_id);
  MergeDocuments.subscribe(
    merge => {
     if(merge['status']==200){
      
     }       
    }
  )
}
  async pdfChallan(user_id,app_id){


    var generatereceipt =  this.api.OnlinePaymentChallan(this.user_id,this.payment_amount,this.payment_status,this.application_id,this.payment_date_time,this.order_id,this.transaction_id);
    generatereceipt.subscribe(
      data => {
        if(data['status'] == 200){


  //  this.Download(this.application_id,this.user_id)
      };
      },
      error => {
          console.error("Error", error);
      }
    );
  }
  

  redirect(){
    this.router.navigateByUrl('/pages/application');
  }

    Download(user_id,app_id){
    //const dbConfig = 'D:/Newfolder/supdcServer'
const dbConfig ='/var/www'
    // this.adminApi.showprovisionalLetterApp(user_id,course_id,application_id).subscribe(data => {
    //   if(data[`status`] == 200){
    var pdf =dbConfig+  "/public/upload/documents/"+user_id+'/'+app_id+'_Payment_Challan.pdf'
    this.api.downloadFiles(pdf)
    .subscribe(data => {
      saveAs(data, app_id+'_Payment_Challan.pdf');
    });

    //   }else{
    //     alert("You Can't Download Preview Letter!!!!!!")
    //   }

    // })
  }
  feedbacks(){    
    this.website_satisfy = 0;
    this.website_recommend = 0;
    this.staff_satisfy = 0;
    this.experience_problem = 0;
  }
  saveFeedback() {    
    var validation = true;
    if (this.feedfack.controls.satisfy.value == null || this.feedfack.controls.satisfy.value == '' || this.feedfack.controls.satisfy.value == undefined) {
      this.feedfack.controls.satisfy.markAsDirty();
      this.validations_flag = true;
      validation = false;
      this.feedback_message = "Please select option for satisfaction of website";
      this.timer();      
    }
    if (this.feedfack.controls.recommend.value == null || this.feedfack.controls.recommend.value == '' || this.feedfack.controls.recommend.value == undefined) {
      this.feedfack.controls.recommend.markAsDirty();
      this.validations_flag = true;
      validation = false;
      this.feedback_message = "Please select option for recommendation of website";
      this.timer();      
    }
    if (this.feedfack.controls.staff.value == null || this.feedfack.controls.staff.value == '' || this.feedfack.controls.staff.value == undefined) {
      this.feedfack.controls.staff.markAsDirty();
      this.validations_flag = true;
      validation = false;
      this.feedback_message = "Please select option for satisfaction of technical team service";
      this.timer();      
    }
    if (this.feedfack.controls.experience.value == null || this.feedfack.controls.experience.value == '' || this.feedfack.controls.experience.value == undefined) {
      this.feedfack.controls.experience.markAsDirty();
      this.validations_flag = true;
      validation = false;
      this.feedback_message = "Please select option for problem experience";
      this.timer();      
    }
    if (this.feedfack.controls.experience.value == 'yes') {
      this.feedfack.controls.exp_prob.markAsDirty();
      if(this.feedfack.controls.exp_prob.value==null || this.feedfack.controls.exp_prob.value==""){
        validation = false;        
      }else{
        validation = true;        

      }
      this.validations_flag = true;     
      this.feedback_message = "You have selected yes option for experience problem. Please state your problem";
      this.timer();      
    }
    if ((this.feedfack.controls.satisfy.value == null || this.feedfack.controls.satisfy.value == '' || this.feedfack.controls.satisfy.value == undefined) &&
      (this.feedfack.controls.recommend.value == null || this.feedfack.controls.recommend.value == '' || this.feedfack.controls.recommend.value == undefined) &&
      (this.feedfack.controls.staff.value == null || this.feedfack.controls.staff.value == '' || this.feedfack.controls.staff.value == undefined) &&
      (this.feedfack.controls.experience.value == null || this.feedfack.controls.experience.value == '' || this.feedfack.controls.experience.value == undefined)) {
      this.validations_flag = true;
      validation = false;
      this.feedback_message = "Please fill feedback form ";
      this.timer();      
    }    
    if (validation) {      
      this.api.feedBack(this.feedfack.controls.satisfy.value, this.feedfack.controls.recommend.value, this.feedfack.controls.staff.value, this.feedfack.controls.experience.value, this.feedfack.controls.exp_prob.value, this.feedfack.controls.suggestion.value)
        .subscribe(
          (data: any) => {
            if(data['status']==200){
              this.displayNo=false

            }
            // this.FirmLetterStep();
          },
          error => {
            console.error("Error", error);
          }
        );
    }
    
  }
  timer() {
    setTimeout(() => {
      //this.onClose();
      this.validations_flag = false;
    }, 1000);
  }
  stateProblem(value) {
    if (value == "yes") {
      this.showExperience = true;
    } else if (value == "no") {
      this.showExperience = false;
    }
  }
  
}

