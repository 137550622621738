<div class="mcr">
  <div class="imge">
    <img src="https://img.icons8.com/bubbles/120/winner.png" alt="winner" />
  </div>
  <h1 id="title" class="title">Change password</h1>
  <p class="sub-title">Please set a new password</p>

  <nb-alert *ngIf="showMessages?.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <nb-alert *ngIf="showMessages?.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Hooray!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
  </nb-alert>

  <div [formGroup]="resetPasswordForm" aria-labelledby="title">

    <div class="form-control-group">
      <label for="input-password">Password:</label>
      <input nbInput autofocus fullWidth id="input-password" formControlName="password" type="password"
        [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" class="first"
        placeholder="Password">
      <ngx-validation-message label="Password" [showMinLength]="password?.hasError('minlength') && password.touched"
        [showMaxLength]="password?.hasError('maxlength') && password.touched"
        [showRequired]="password?.errors?.required && password.touched" [minLength]="minLength"
        [maxLength]="maxLength"></ngx-validation-message>
    </div>

    <div class="form-control-group">
      <label for="input-re-password">Confirm Password:</label>
      <input nbInput fullWidth id="input-re-password" formControlName="confirmPassword" type="password"
        [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
        placeholder="Password">
      <ngx-validation-message label="Confirm Password"
        [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
        [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
        [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched" [minLength]="minLength"
        [maxLength]="maxLength"></ngx-validation-message>
      <p class="error-message caption status-danger"
        *ngIf="password.value != confirmPassword.value && confirmPassword.touched">
        Password and confirm password does not match!
      </p>
    </div>

    <button nbButton status="success" fullWidth
      [disabled]="submitted || !resetPasswordForm.valid || password.value != confirmPassword.value"
      [class.btn-pulse]="submitted" (click)="resetPass()">
      Change password
    </button>
  </div>
  <h6> Don't have an account? <a style="color:DodgerBlue;" class="text-link" routerLink="../register">Register</a></h6>
</div>