<div class="mcr">
  <div class="imge">
    <img src="https://img.icons8.com/bubbles/120/question-mark.png" alt="question-mark" />
  </div>
  <h1 id="title" class="title">Forgot Password</h1>
  <p class="sub-title">Enter your email address and we’ll send a link to reset your password</p>

  <nb-alert *ngIf="showMessages?.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <nb-alert *ngIf="showMessages?.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Hooray!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
  </nb-alert>

  <div [formGroup]="requestPasswordForm" aria-labelledby="title">

    <div class="form-control-group">
      <label for="input-email">Email address:</label>
      <input nbInput autofocus fullWidth fieldSize="large" id="input-email" formControlName="email"
        [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
        [attr.aria-invalid]="email.invalid && email.touched ? true : null" placeholder="Email address">
      <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
        [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
    </div>


    <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !requestPasswordForm.valid"
      [class.btn-pulse]="submitted" (click)="requestPass()">
      Request password
    </button>
  </div>
  <h6> Don't have an account? <a style="color:DodgerBlue;" class="text-link" routerLink="../register">Register</a>
  </h6>
</div>