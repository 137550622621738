import { Component, OnInit } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import {  ActivatedRoute, Router } from '@angular/router';
// import { CountriesService } from '../../@core/data/countries.service';
// import { CountriesService } from '../../@core/data/countries.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { ConfirmationService } from 'primeng/api';
import {NbDialogService } from '@nebular/theme';
import { ShowNotesComponent } from '../../user/Shownotes.component';
import { NotesComponent } from '../../user/notes.component';
import { Data } from '../../../../assets/data/data';
import { Subject } from 'rxjs';
import { UserStore } from '../../../@core/stores/user.store';
import { User, UserData } from '../../../@core/interfaces/common/users';
import { takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'ngx-admin-pending',
  templateUrl: './admin-pending.component.html',
  styleUrls: ['./admin-pending.component.scss'],
  providers:[ConfirmationService],
})
export class AdminPendingComponent implements OnInit {
  Countries: any [];
  p: number = 1;
  public filterText: string;
  public filterPlaceholder: string;
  public filterInput = new FormControl();
  selectedYear ='2019'
  studentdata: any;
  studentdata_requested: any;
  loadingXL: boolean;
  tab1:boolean;

  tab2:boolean;
  tab3:boolean;
  index = 0;
  studentdata_changed: any;
  active: any;
  searchForm:FormGroup;
  name : string;
  email : string;
  isLoadingResults: boolean = false;
  studentLength : any;
  student_changed_Length : any;
  student_requested_Length : any;
  student_name : any;
  app_id: any;
  applicationdata: any;
  notes: any;
  toastrService: any;
  user_id: any;
  totalLength: any;
  displayVideo2: boolean;
  type: any;
  id: any;
  user: any;
  role: any;
  adminemail: any;
  usersData: any;
  protected readonly unsubscribe$ = new Subject<void>();
  viewFrom: string;
  userId: string;
  newapp: string;
  admin: any;
  userid: any;
  email_admin: any;
  studentdata_rejected: any;
  studentdata_rejected_Length: any;
  rejectedapp: boolean;
  display: boolean;
  selected_value =[];
  // email : any;
  constructor(  private api: ApplicationApi,
    private userStore: UserStore,
    private route: ActivatedRoute,

    private authService : NbAuthService,
    private router : Router,
    // protected countries :CountriesService,
    public toasterService: NbToastrService,
    public dialogservice: NbDialogService,
    protected globalVar:Data,
    private formBuilder:FormBuilder,
    private ConfirmationService: ConfirmationService,
    private usersService: UserData)
     {
      {
        this.viewFrom = this.route.snapshot.queryParamMap.get('viewFrom');
        this.userId = this.route.snapshot.queryParamMap.get('userId');
        this.newapp = this.route.snapshot.queryParamMap.get('newapp');
        this.app_id= this.route.snapshot.queryParamMap.get('app_id');
    
       }
      

   }

  ngOnInit() {
    this.filterText = "";
    this.filterPlaceholder = "Local Search";
    this.refresh(null,null,null);
    this.searchForm = this.formBuilder.group({
      nameCtrl:[''],
      emailCtrl:[''],
       idCtrl : [''],
       outward:[''],
    })
    if(this.userStore.getUser() == undefined){      
      this.usersService.getCurrentUser().pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        
        
            this.id=this.user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;
            this.email_admin = user.data.email;
            

            
      })
    
          this.userStore.onUserStateChange()
          .pipe(
            takeUntil(this.unsubscribe$),
          )
          .subscribe((user: User) => {
            
            
            this.id=user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;    
            this.email_admin = this.user.email;

          });
    
      }else{        
        this.user=this.userStore.getUser();
        this.id=this.user.id;
        this.role=this.user.role;
        
        this.email_admin = this.user.email;

    
      }
      var userId=this.route.snapshot.queryParamMap.get('userId');
      
        // this.api.getAdminSideDetails(userId).subscribe(data => {
        //   this.usersData= data['data'][0] ? data['data'][0] : null;    
        //   });
  }
  changetab(e:MouseEvent,tabIndex){
    
    
    if(tabIndex==0){
      this.api.getAll_PendingApplications(this.p,'','','').subscribe(data =>{
        this.isLoadingResults = false;
        this.studentdata = data['items'];
        
        
        this.studentLength = data['total_count'];
        this.totalLength = this.studentLength.totalLength;
        
        
        // this.active=data['counts'];
      })
    }else if(tabIndex==1){
      this.api.getAll_RequestedApplications(this.p,'','','').subscribe(data =>{
        this.isLoadingResults = false;
        this.studentdata_requested = data['items'];
        
        // 
        this.student_requested_Length = data['total_count'];
        this.totalLength = this.studentLength.totalLength;
        
        // this.active=data['counts'];
      })

    }else if(tabIndex==3){

    this.api.getAll_ChangedApplications(this.p,'','','').subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata_changed = data['items'];
      this.student_changed_Length = data['total_count'];
      this.totalLength = this.studentLength.totalLength;
      
      // this.active=data['counts'];
    })
    }

    else if(tabIndex==4){
      this.api.getAll_RejectedApplications(this.p,'','','').subscribe(data =>{
        this.isLoadingResults = false;
        this.studentdata_rejected = data['items'];
  
        this.studentLength = data['total_count'];
        this.totalLength= this.studentLength.totalLength
        // this.active=data['counts'];
      })        }



  }
  handleClick(user_id,app_id,type) {

    this.router.navigate(['pages/adminView'],{queryParams:{userId : user_id, app_id : app_id, viewFrom : type}});
  }
  pageChanged(p){
    this.p = p;
    this.globalVar.ViewpageValue=p;
     this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value);
  
  }

  search(){
    
    
    if(this.searchForm.controls.idCtrl.value || this.searchForm.controls.nameCtrl.value || this.searchForm.controls.emailCtrl.value){
      this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value);
    }else{
      alert("Please specify any of the search criteria")
    }
  }
  addNotes(app_id,userid,email_admin){



    
    this.dialogservice.open(NotesComponent, {
      context: {

           app_id: app_id,
           type:'notes',
           userid:userid,
           email_admin : this.email_admin
      },
    }).onClose.subscribe(data=>{
      this.getapplication()
    })

  }
  getapplication(){
    this.api.getapplication(this.app_id).subscribe(data => {
      if(data['status'] == 200){
        this.applicationdata=data['data']
        this.notes= this.applicationdata[0].notes ? this.applicationdata[0].notes : ''
        
       
      }else{
        this.toastrService.danger('Error Occured','Error',{preventDuplicates: true});
      }
    });
   }


  refresh(application_id,student_name, email){
    student_name = student_name;
    email = email;
    this.isLoadingResults = true;
    this.api.getAll_PendingApplications(this.p,application_id,student_name,email).subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata = data['items'];

      this.studentLength = data['total_count'];
      this.totalLength = this.studentLength.totalLength;
      
      
      // this.active=data['counts'];
    })
    this.api.getAll_RequestedApplications(this.p,application_id,student_name,email).subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata_requested = data['items'];
      
      // 
      this.student_requested_Length = data['total_count'];
      this.totalLength = this.studentLength.totalLength;
      
      // this.active=data['counts'];
    })
    this.api.getAll_ChangedApplications(this.p,application_id,student_name,email).subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata_changed = data['items'];
      this.student_changed_Length = data['total_count'];
      this.totalLength = this.studentLength.totalLength;
      })

      this.api.getAll_RejectedApplications(this.p,application_id,student_name,email).subscribe(data =>{
        this.isLoadingResults = false;
        this.studentdata_rejected = data['items'];
        
  
        this.studentdata_rejected_Length = data['total_count'];
        this.totalLength= this.studentLength.totalLength
        // this.active=data['counts'];
      })


  
    this.filterInput
      .valueChanges
      // .debounceTime(200)
      .subscribe(term => {
       this.filterText = term;
    });
  }


  verify(id,user_id,type,email_admin){


this.email_admin=email_admin

if(email_admin.includes('@edulab.in')){
  this.toasterService.warning("you don't have access to verify application");
}
else{
  this.displayVideo2=true
  this.user_id=user_id,
  this.app_id=id,
  this.type=type
}
  }

  save(){

    this.api.setVerified(this.app_id , this.type, this.user_id,this.searchForm.controls.outward.value,this.email_admin).subscribe((data: any) => {
      if(data['status'] ==  200){
        this.toasterService.success('Application ' + this.app_id + data['message']);
        this.displayVideo2=false
        this.refresh(null,null,null);

      }
      else{
        if(data['response'] ==  200){
          this.toasterService.warning("you don't have access to verify application");
          // this.displayVideo2=false
          // this.refresh(null,null,null);
  
        }
      }
    })
 
    
      
    }

    toggle(event,user_id,id){
      
      
      if(event==true){
        
        
        this.selected_value.push({
          user_id:user_id,
          app_id:id
        })
  
      }else if(event==false){
        
        
        this.selected_value.splice(this.selected_value.findIndex(data => data.user_id === user_id) , 1)
  
      }
      
    }
    megecertificate(megecertificate){
      
      this.api.mergemark(megecertificate).subscribe((data)=>{
        if(data['status']==200){
          this.toasterService.success('Email Sent Succesfully','Success',{preventDuplicates:true});
  
          this.refresh(null,null,null);
        }
  
      })
      
    }
    //for requested to changed 
    verify2(id,user_id,type,email_admin){
      
      // 
      this.email_admin=email_admin
  this.user_id=user_id,
  this.app_id=id,
  this.type=type
      this.api.setVerified(this.app_id , this.type, this.user_id,'',this.email_admin).subscribe((data: any) => {
        if(data['status'] ==  200){
          this.toasterService.success('Application ' + this.app_id + data['message']);
          // this.displayVideo2=false
          this.refresh(null,null,null);
  
        }
        else{
          if(data['response'] ==  200){
            this.toasterService.warning("you don't have access to verify application");
        }
      }
      })
    }
  showNotes(id,user_id,notes){

  var app_id = id;
  var user_id = user_id;
  this.dialogservice.open(ShowNotesComponent, {
    context : {
      app_id : id,
      notes_area : notes,
      // collegeConfirmation : collegeConfirmation
    },
  }).onClose
  .subscribe(
    (data: any) => {
      // this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value);
    }
  )
  // this.api.getNotes(id,user_id).subscribe((data: any) => {
  //       if(data['status'] ==  200){
  //         this.toasterService.success('Application ' + id + ' set to verified');
  //         this.refresh(this.student_name, this.email);
  //       }
  //     })
  }
  downloadExcel(tabtype){
    
     
     
         this.api.downloadExcel(tabtype).subscribe((response)=>{
            
            if(response['status']==200){
//  const dbConfig ='D:/pdc2/supdcServer'
 const dbConfig ='/var/lib/docker/volumes/publicData/_data'
     
      var pdf =dbConfig+  "/public/upload/Excel/"+tabtype+'.xlsx'
      this.api.downloadFiles(pdf)
      .subscribe(data => {
        saveAs(data, tabtype+'.xlsx');
      });
            }
             })
    
      };

      Reject(id,user_id,type){
        
        var email = this.email_admin
        if(email.includes('@edulab.in')){
          this.display =false;
          this.toasterService.warning("you don't have access to reject the application")

        }
        else{
        this.user_id=user_id;
        this.app_id=id;
        this.type=type;
        this.display =true;
        
        this.searchForm.controls.outward.patchValue('')
        }
        // this.api.setVerified(this.app_id ,this.type,this.user_id, this.searchForm.controls.outward.value,this.email_admin).subscribe((data: any) => {
        //   if(data['status'] ==  200){
        //     this.toasterService.success('Application ' + this.app_id + ' Rejected');
            // this.display =true;
        //     this.refresh(null,null,null);
        //   }
        // })
          
          
        
        
        
      }
      ok(id,user_id,type){
        // this.user_id=user_id;
        // this.app_id=id;
        
        
         this.user_id=user_id;
        this.type=type;
        this.display = false;
        this.api.setVerified(this.app_id ,this.type,this.user_id, this.searchForm.controls.outward.value,this.email_admin).subscribe((data: any) => {
          // this.display = false;
          
          
          if(data['status'] ==  200){
            // this.display = true;
            this.toasterService.success('Application ' + this.app_id + ' Rejected');
            this.display =false;
            this.refresh(null,null,null);
          }
        })
        // this.display = false;
       
    
      }
    
}
