
 <!-- <nb-card>

    <nb-card-header>Pdf Viewer<nb-action><button nbButton hero status="primary" style="margin-left:1000px;" (click)="dismiss()">Close</button></nb-action> </nb-card-header> -->

     <!-- <nb-card-header>Pdf Viewer <i class="fa fa-window-close" (click)="dismiss()" style="margin-left: 15%;" aria-hidden="true"></i> </nb-card-header>   -->
     <!-- <nb-card-body>
        <embed id="viewpdf" src="" frameborder="0" width="100%" height="400px">
    </nb-card-body>
  </nb-card> -->
<!--
  <nb-actions size="large" style="float: right;">
    <nb-action icon="nb-close"  (click)=dismiss()></nb-action>
    </nb-actions> -->
<!-- <nb-card>
    <div class = "row">
        <button nbButton hero status="primary"style="margin-left:15px;" (click)="dismiss()">Close</button>
     </div>
     </nb-card> -->

     

     <!-- <nb-card style="height: 400px; width: 800px;" status="primary"> -->
          <!-- <nb-actions size="large" style="float: right;">
              <nb-action icon="nb-close"  (click)="close()"></nb-action>
          </nb-actions> -->
          <!-- <nb-card-header><button nbButton hero status="primary" style="margin-left:650px;" (click)="dismiss()">Close</button> -->
          <!-- <div > -->
          <!-- <button *ngIf = "type !='pdf'" (click)="rotateClockwise()"><i class="fas fa-rotate-right"></i></button>
          <button *ngIf = "type !='pdf'" (click)="rotateCounterclockwise()"><i class="fas fa-rotate-left"></i></button>
          </nb-card-header>
      <nb-card-body>

          <div *ngIf = "type !='pdf'">
              <img [src]="filename" height=":500px;" width="100%;" alt="Random first slide" [style.transform]="'rotate(' + rotationAngle + 'deg)'">
          </div> -->
          <!-- </div> -->
          <!-- <div class="pdf-container" *ngIf = "type =='.pdf'">
              <ngx-extended-pdf-viewer src="{{filename}}" [showSecondaryToolbarButton]="false"
              [ignoreResponsiveCSS]="ignoreResponsiveCSS" useBrowserLocale="true" [height]="'70vh'"
              [showDownloadButton]="false" [showOpenFileButton]="false" [showBookmarkButton]="false">
              </ngx-extended-pdf-viewer>
          </div> -->
           <!-- <div class="pdf-container">
                <nb-card-body>
                    <embed id="viewpdf" src="" frameborder="0" width="100%" height="400px">
                </nb-card-body>
            </div>
      </nb-card-body>
  </nb-card> -->






  <nb-card style="width: 75vw; height: 80vh;" status="primary">
    <nb-card-header>Document <button *ngIf="type !='pdf'" (click)="rotateClockwise()"><i class="fas fa-rotate-right"></i></button>&nbsp;&nbsp;<button *ngIf="type !='pdf'" (click)="rotateCounterclockwise()"><i class="fas fa-rotate-left"></i></button><button nbButton hero status="primary" style="margin-left:700px;" (click)="dismiss()">Close</button></nb-card-header>

 <nb-card-body>
     <div *ngIf="type !='pdf'">
         <img [src]="filename" height=":90%" width="100%;" alt="Random first slide" [style.transform]="'rotate(' + rotationAngle + 'deg)'">
     </div>
     <div class="pdf-container">
         <nb-card-body>
             <embed id="viewpdf" src="" frameborder="0" width="100%" height="700px">
         </nb-card-body>
     </div>
 </nb-card-body>
</nb-card>
<nb-card-footer></nb-card-footer> 