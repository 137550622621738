import { Component, Input} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Router} from '@angular/router';
import { ApplicationApi } from '../../@core/backend/common/api/application.api';
import {  NbToastrService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Component({
    selector: 'nb-dialog',
    template: `
        <nb-card height="500px;" width="500px;">
            <nb-card-header>
                Notes h
            </nb-card-header>
            <nb-card-body>
                <span style="font-weight : bold; font-size : 16px;" [innerHTML]="notes_area"></span><br/>
                <br/>
                <button nbButton (click)="addNotes()" *ngIf="notes_area == null">Add</button>
                <button nbButton (click)="addNotes()" *ngIf="notes_area != null">Edit</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <!-- <mat-checkbox class="example-margin" [checked]="collegeConfirmation == true"  (change)="setCollegeConfirmation($event)">College Confirmation OK</mat-checkbox> -->
                <br/>
                <br/>
                <div  *ngIf="addNote == true">
                    <div class="row">
                        <div class="col-md-12">
                            <tinymce [(ngModel)] = "notes" ></tinymce>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <button nbButton (click)="saveNotes()">Save</button>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    `,
    })
export class ShowNotesComponent {
    @Input() app_id: any;
    @Input() notes_area : any;
    // @Input() collegeConfirmation;
    notes;
    addNote: boolean = false;
    constructor(protected ref: NbDialogRef<ShowNotesComponent>,
      private router : Router,
      private authService: NbAuthService,
      protected api : ApplicationApi,
      private toastrService: NbToastrService,
      ) {
          
    }


    close(){
        this.ref.close();
    }

    addNotes(){
        this.addNote = true;
        this.notes = this.notes_area;
      }
      
    saveNotes(){
        this.api.saveNotes(this.app_id,this.notes).subscribe(data=>{
          this.notes_area = data['data'];
          this.toastrService.show('Succesfully Saved.','Success');
          this.ref.close();

        })
      }
    

    // setCollegeConfirmation(event){
   
    //     this.api.setCollegeConfirmation(this.app_id,event.checked).subscribe(data=>{
    //       if(data['status'] == 200){
    //         if(data['collegeConfirmation'] == true){
    //           this.collegeConfirmation = true
    //         }
    //         this.toastrService.success('College Confirmation updated','',{
    //           duration :3000
    //         })
    //       }else{
    //         if(data['collegeConfirmation'] == null){
    //           this.collegeConfirmation = false
    //         }
    //         this.toastrService.danger('Something went wrong','',{
    //           duration :3000
    //         })
    //       }
    //     })
      
    // }
    
}