import { Injectable } from '@angular/core';

@Injectable()
export class Data {

   
    public ViewpageValue: any;
    public tabIndex: any;
    public ViewTabValue: any;
   
    public constructor() { }

}