import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';
import { ApplicationApi } from '../../@core/backend/common/api/application.api';
import { FormBuilder, FormGroup,Validators} from '@angular/forms';
// import { ApiService } from '../../../shared/api.service';
import { NbToastrService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UserStore } from '../../@core/stores/user.store';



@Component({
    selector: 'nb-dia',
    template: `<nb-card status="success" style="width: 900px; height:500px; display: flex ;">
  <nb-card-body>
  <form [formGroup]="changePasswordForm" >
				<nb-alert status="danger" closable (close)="close()" *ngIf="alertflag == 1">
						{{validation_messages}}
				</nb-alert>

				<nb-alert status="danger" closable (close)="close()" *ngIf="alertflag == 2">
					{{validation_messages}}
				</nb-alert>

				<nb-alert status="danger" closable (close)="close()" *ngIf="alertflag == 3">
					{{validation_messages}}
				</nb-alert>
				<nb-alert status="danger" closable (close)="close()" *ngIf="alertflag == 4">
						{{validation_messages}}
					</nb-alert>

				<nb-alert status="danger" closable (close)="onClose()" *ngIf="messagealertflag == 1">
					Success! Your Password has been changed!
			</nb-alert>


            <h1 id="title" class="title">ChangePassword</h1>
            <p class="sub-title">Please set a new password</p>
            <br>
            <br>


		<div class="row">
			<div class="col-lg-12">
				<div class="form-group">
					<label for="inputPassWord">Password  </label>
					<input type="password" class="form-control"  name="password" id="inputPassWord" formControlName="passwordCtrl"  placeholder="Password" style="width: 60%;">
				</div><br>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-12">
			<div class="form-group">
				<label for="inputRePassWord">Confirm Password</label>
				<input type="password" class="form-control"  name="confirmpassword"id="inputRePassWord"  formControlName="repasswordCtrl"  placeholder="Confirm Password" style="width: 60%;">
			</div>
			</div>
		</div><br>

<br><br>
<div class="row">
	<div class="offset-lg-4"></div>
			<button  style="width: 150px; border-radius: 10px; color: white; background-color: blue; height: 50px;" (click)="onSubmit()">Proceed</button>
	</div>

    `,
})
export class changepass implements OnInit{
    validation_messages;
    alertflag = 0;
    messagealertflag=0;

    @Input() userPassword:string;
    @Input() userConfirmPassword:string;

    changePasswordForm: FormGroup;

    readonly passwordValidate = /^[A-Za-z0-9!@#$%^&*()_]{6,}$/;
    newapp: any;
    editFlag: any;
    app_id: any;
    getuser: any;
    edu_data: any;
    emails: any;
    email: string;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private authService: NbAuthService,
        protected api: ApplicationApi,
        private toastrService: NbToastrService,
        public toasterService: NbToastrService,
        private UserStore: UserStore,
    ) {

        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
            });
    }
 ngOnInit() {
  this.getalldata()

          this.changePasswordForm = this.fb.group({

          passwordCtrl:['',[Validators.required, Validators.pattern(this.passwordValidate)]],
          repasswordCtrl:['',[Validators.required, Validators.pattern(this.passwordValidate)]],

          });

        }
        
        getalldata(){
          this.api.getalldata(this.newapp,this.editFlag,this.app_id).subscribe(data => {
          
      
              if(data['status']==200){
                this.getuser=data['getuser']['0']
                
                this.emails= this.getuser.email
                // this.edudetails=data['edudetails']
                 
                
                this.edu_data=data['data']
                
              }
              
                
      
      
              
      
               
            // }
        });
          
         }

        onSubmit(){
          
          
          this.changePasswordForm.controls.passwordCtrl.markAsDirty();
          this.changePasswordForm.controls.repasswordCtrl.markAsDirty();

          var userPassword= this.changePasswordForm.controls.passwordCtrl.value;
          var userConfirmPassword= this.changePasswordForm.controls.repasswordCtrl.value;


          if(userPassword =='' || userConfirmPassword ==''){
            this.alertflag = 1;
            this.validation_messages =  "Your form is not filled please fill completely";

          }else if (!(userPassword == userConfirmPassword)){
                this.alertflag = 2;
                this.validation_messages =  "Password doesn't match!";
          }
          else{
              if(userPassword.length<=5 && userConfirmPassword.length<=5){
                  this.alertflag = 4;
                  this.validation_messages =  "Use 5 or more characters,numbers or symbols ";
              }else{

                  var changePasswordvalue={
                    password:this.changePasswordForm.controls.passwordCtrl.value,
                    confirmPassword:this.changePasswordForm.controls.repasswordCtrl.value,
                      // email:this.UserStore.getUser()["email"]

                  }


                  this.alertflag = 5;
                  this.validation_messages =  "Your password and confirm password successfully match";

                  this.api.resetPasswordValues(changePasswordvalue,this.emails)
                  .subscribe((data: any) => {
                      if(data['status'] == 200){
                      this.toasterService.success('Password changed successfully!!','Success',{duration: 3000});
                      setTimeout(() =>{
                        this.router.navigate(['/auth/logout']);
                      },1000)

                      }else if(data['status'] ==400){
                        this.toastrService.danger('Same Password Kindly Try With Another Password','Error',{duration:3000});
                      this.messagealertflag = 0;
                      }else{
                      }
                      err => console.error(err)
                  });
              }
          }
      }


        close() {
          this.alertflag = 0;
        }

        onClose() {
          this.messagealertflag = 0;
        }
}