<nb-card>
  <nb-card-header status="success">
    <label style="font-size: 140%;">Total Applications </label>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="searchForm" class="row">
      <div class="col-xl-4 form-group">
        <label>Application Id</label><br>
        <input nbInput formControlName="idCtrl" placeholder="Application Id"
          [ngClass]="{'form-control-danger': searchForm.controls.idCtrl.invalid && (searchForm.controls.idCtrl.dirty || searchForm.controls.idCtrl.touched)}">
      </div>
      <div class="col-xl-4 form-group">
        <label>Name</label><br>
        <input nbInput formControlName="nameCtrl" [(ngModel)]="name" placeholder="name"
          [ngClass]="{'form-control-danger': searchForm.controls.nameCtrl.invalid && (searchForm.controls.nameCtrl.dirty || searchForm.controls.nameCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <label>Email ID</label><br>
        <input nbInput formControlName="emailCtrl" [(ngModel)]="email" placeholder="email"
          [ngClass]="{'form-control-danger': searchForm.controls.emailCtrl.invalid && (searchForm.controls.emailCtrl.dirty || searchForm.controls.emailCtrl.touched)}">
      </div>  

      <div class="col-md-3">
        <label>Order ID</label><br>
        <input nbInput formControlName="orderCtrl" placeholder="Order"
          [ngClass]="{'form-control-danger': searchForm.controls.orderCtrl.invalid && (searchForm.controls.orderCtrl.dirty || searchForm.controls.orderCtrl.touched)}">
      </div>

      <div class="col-xl-2">
        <button (click)="search()">Search</button>
      </div>
    </form>


    <br>
    <div class="row">
      <div class="col-lg-6 ">
        <mat-form-field class="example-full-width">
          <input matInput type="text" [value]="filterText" [placeholder]="filterPlaceholder"
            [formControl]="filterInput" />
          <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>
    </div>

    <div class="wrapper">
      <button style="float: right;" status="success" (click)="downloadExcel('Total')">
        <div style="font-size: 86%;">
          Download Excel
        </div>
      </button>
      <h5><b>Total student count: {{studentLength?.totalLength}} </b></h5><br /><br />
      <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->

      <table id="selectedColumn">
        <thead>
          <tr>
            <th>App_ID</th>
            <th>Name As Per Marksheet</th>
            <th>Email ID</th>
            <th>Application Tracker</th>
            <th>Application Status</th>
            <th>Service type</th>
            <th>Application Date</th>
            <th>Action</th>
            <th>Order_id</th>

          </tr>
        </thead>
        <tbody>
          <tr
          *ngFor="let student of studentdata | filter:{name:filterText , email:filterText , id:filterText} : false | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
            <td>
              {{student?.id}}
            </td>
            <td>
              {{student?.fullname}}
            </td>
            <td>
              {{student?.email}}
            </td>
            <td>
              {{student?.tracker}}
            </td>
            <td>
              {{student?.status}}
            </td>
            <td>
              <div class="Rtable-cell--content date-content" *ngIf="student.servicetype==436 || student.servicetype==298">Courier {{student.servicetype}}</div>
              <div class="Rtable-cell--content date-content" *ngIf="student?.servicetype==200">Pickup {{student.servicetype}}</div>
              <div class="Rtable-cell--content date-content" *ngIf="student?.servicetype==253">Mail {{student.servicetype}}</div></td>
            <td>
              {{student?.created_at}}
            </td>
            <td>
              <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                (click)="handleClick(student.user_id,student.id)"></i><br>
              <button nbButton outline size="small"
                *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                (click)="verifyOtp('0', '0', student.id);">Verify</button>
              <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                (click)="status('inactive',student.id);">inactive</button>
              <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                (click)="status('active',student.id);">active</button>
            </td>
            <td>
              {{student?.order_id}}
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="studentLength?.totalLength == 0">
        <span style="color : red; font-size: 18px;">No Data Available</span>
      </div>
    </div>
    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
  </nb-card-body>
</nb-card>